import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import type { Location } from "react-router-dom";

import { Layout } from "@/layout";
import {
  ConceptsAndUseCases,
  CreateModule,
  ForgotPassword,
  ForgotPasswordConfirmation,
  IndexView,
  Login,
  Modules,
  ModuleUnits,
  ModuleView,
  NotFound,
  ResetPassword,
  ResetPasswordConfirmation,
  SetPassword,
  SetPasswordConfirmation,
  UsersManagement,
} from "@/screens";
import { Editor } from "@/screens/Admins/Slides/Editor";
import { SlideViewer } from "@/screens/Admins/Slides/Viewer";
import { ModalRouter } from "./ModalRouter";
import { ProtectedRoute } from "./ProtectedRoute";
import { ROUTES } from "./routes";

export const Router = () => {
  const location = useLocation();
  const { previousLocation } = (location.state ?? {}) as {
    previousLocation?: Location;
  };

  return (
    <>
      {/* PUBLIC ONLY ROUTES */}
      <Routes location={previousLocation ?? location}>
        <Route element={<ProtectedRoute expected={["loggedOut"]} />}>
          <Route element={<IndexView />} path={ROUTES.base} />

          <Route element={<Login />} path={ROUTES.login} />
          <Route
            element={<ForgotPassword />}
            path={ROUTES.forgotPassword.forgotPassword}
          />
          <Route
            element={<ForgotPasswordConfirmation />}
            path={ROUTES.forgotPassword.confirmation}
          />
          <Route
            element={<ResetPassword />}
            path={ROUTES.resetPassword.resetPassword}
          />
          <Route
            element={<ResetPasswordConfirmation />}
            path={ROUTES.resetPassword.confirmation}
          />
          <Route
            element={<SetPassword />}
            path={ROUTES.setPassword.setPassword}
          />
          <Route
            element={<SetPasswordConfirmation />}
            path={ROUTES.setPassword.confirmation}
          />
        </Route>

        {/* PRIVATE ONLY ROUTES */}
        <Route element={<ProtectedRoute expected={["Super Admin", "Admin"]} />}>
          <Route
            element={<Editor />}
            path={ROUTES.conceptsAndUseCases.editUnitSlides}
          />
          <Route
            element={<SlideViewer />}
            path={ROUTES.conceptsAndUseCases.viewUnitSlides}
          />
          <Route element={<Layout />}>
            <Route element={<Navigate to={ROUTES.home} />} path={ROUTES.base} />

            <Route
              element={<ConceptsAndUseCases />}
              path={ROUTES.conceptsAndUseCases.base}
            />
            <Route
              element={<CreateModule />}
              path={ROUTES.conceptsAndUseCases.createModule}
            />
            <Route
              element={<ModuleUnits />}
              path={ROUTES.conceptsAndUseCases.moduleUnits}
            />

            <Route
              element={<UsersManagement />}
              path={ROUTES.adminUsersManagement}
            />
            <Route path={ROUTES.notFound} element={<NotFound />} />
          </Route>
        </Route>

        <Route element={<ProtectedRoute expected={["User"]} />}>
          <Route element={<Layout />}>
            <Route element={<Modules />} path={ROUTES.modules.base} />
            <Route element={<ModuleView />} path={ROUTES.modules.moduleUnits} />
          </Route>
          <Route element={<SlideViewer />} path={ROUTES.modules.unitSlides} />
        </Route>
      </Routes>
      <Routes>
        <Route
          path="*"
          element={<ModalRouter showModal={!!previousLocation} />}
        />
      </Routes>
    </>
  );
};
