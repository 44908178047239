import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  acceptInvitation,
  createInvitation,
  deleteInvitation,
  getInvitation,
  getInvitations,
  updateInvitation,
} from "@/api/invitations";
import { errorToast } from "@/ui";

export const invitationQueryKeys = {
  getInvitationsQuery: () => ["getInvitationsQuery"],
  getInvitationQuery: (id: number) => ["getInvitationQuery", id],
};

export const useInvitations = () => {
  const queryClient = useQueryClient();

  const useGetInvitations = () =>
    useQuery({
      queryKey: invitationQueryKeys.getInvitationsQuery(),
      queryFn: getInvitations,
    });

  const useGetInvitation = (invitationId: number) =>
    useQuery({
      queryKey: invitationQueryKeys.getInvitationQuery(invitationId),
      queryFn: () => getInvitation(invitationId),
    });

  const useDeleteInvitation = useMutation({
    mutationFn: deleteInvitation,
    onError: (e) => {
      errorToast(e);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: invitationQueryKeys.getInvitationsQuery(),
      });
    },
  });

  const useCreateInvitation = useMutation({
    mutationFn: createInvitation,
    onError: (e) => {
      errorToast(e);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: invitationQueryKeys.getInvitationsQuery(),
      });
    },
  });

  const useUpdateInvitation = useMutation({
    mutationFn: updateInvitation,
    onError: (e) => {
      errorToast(e);
    },
    onSuccess: (_, params) => {
      void queryClient.invalidateQueries({
        queryKey: invitationQueryKeys.getInvitationQuery(params.id),
      });
    },
  });

  const useAcceptInvitations = useMutation({
    mutationFn: acceptInvitation,
    onError: (e) => {
      errorToast(e);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: invitationQueryKeys.getInvitationsQuery(),
      });
    },
  });

  return {
    useGetInvitations,
    useDeleteInvitation,
    useCreateInvitation,
    useUpdateInvitation,
    useGetInvitation,
    useAcceptInvitations,
  };
};
