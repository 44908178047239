import { useNavigate } from "react-router";
import { t } from "ttag";

import { NoModules } from "@/components/icons";
import { Typography } from "@/components/Typography";
import { ROUTES } from "@/router";
import { Button } from "@/ui";
import { tw } from "@/utils";

export const EmptyModules = ({ className }: { className?: string }) => {
  const navigate = useNavigate();
  return (
    <div className={tw("flex flex-col items-center", className)}>
      <NoModules />
      <Typography className="my-4 text-base text-gray-700" font="semiBold">
        {t`You have no modules created yet`}
      </Typography>
      <Button
        className="w-full"
        variant="tertiary"
        onClick={() => navigate(ROUTES.conceptsAndUseCases.createModule)}
      >
        {t`Create new module`}
      </Button>
    </div>
  );
};
