import type { ReactNode } from "react";

import { tw } from "@/utils";

const SIZE = {
  xs: "px-1 py-0.5",
  sm: "px-2 py-0.5",
  md: "px-2.5 py-1",
  lg: "px-3 py-1.5",
} as const;
type Size = keyof typeof SIZE;

export interface ChipProps {
  size?: Size;
  children?: ReactNode;
  className?: string;
}

export const Chip = ({
  size = "md",
  children,
  className,
  ...props
}: ChipProps) => {
  return (
    <div
      className={tw(
        "flex h-fit w-fit items-center justify-center gap-1 rounded-full border border-transparent text-sm font-medium",

        size && [SIZE[size]],

        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
