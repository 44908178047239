import type { ComponentPropsWithoutRef, ForwardedRef, ReactNode } from "react";

import { ChevronArrowDown } from "@/components";
import { forwardRef, tw } from "@/utils";
import { Label } from "./Label";
import { Message } from "./Message";

export interface SelectProps extends ComponentPropsWithoutRef<"select"> {
  options: string[];
  id: string;
  label?: ReactNode;
  containerClassName?: string;
  value?: string;
  emptyOption?: string;
  message?: string;
  error?: string | boolean;
  disableEmptyOption?: boolean;
  requiredMarker?: boolean;
}

export const Select = forwardRef(
  (
    {
      className,
      containerClassName = "",
      disableEmptyOption = false,
      emptyOption,
      error,
      id,
      label,
      requiredMarker,
      message,
      options,
      style,
      ...rest
    }: SelectProps,
    ref: ForwardedRef<HTMLSelectElement>,
  ) => (
    <>
      {!!label && (
        <Label
          htmlFor={id}
          label={label}
          className="mb-2 text-base font-medium text-gray-700"
          requiredMarker={requiredMarker}
        />
      )}
      <div
        style={style}
        className={tw(
          "relative",
          !!rest.disabled && "rounded-md border-gray-500",
          containerClassName,
        )}
      >
        <select
          ref={ref}
          className={tw(
            "block w-full rounded-md border border-light-base px-3 py-3 text-base text-gray placeholder:text-gray-300 focus:outline-none focus:ring-1 focus:ring-gray-500",
            !!error &&
              "border-danger-700 focus:border-danger-700 focus:ring-red-50",
            !!rest.disabled && "text-light-base-second",
            className,
          )}
          id={id}
          defaultValue=""
          {...rest}
        >
          {emptyOption && (
            <option disabled={disableEmptyOption} value="">
              {emptyOption}
            </option>
          )}
          {options.map((o, index) => (
            <option key={index} value={o} className="bg-red-200">
              {o}
            </option>
          ))}
        </select>
        <ChevronArrowDown
          className={tw(
            "absolute right-1 top-2.5 ml-2 bg-base-second stroke-main-blue",
            rest.disabled && "bg-white",
          )}
        />
        {error && <Message message={message} error={error} />}
      </div>
    </>
  ),
);
