import type { IconProps } from "./IconProps";

export function InviteUpdated({ className, ...props }: IconProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={120}
      height={87}
      fill="none"
      {...props}
    >
      <path
        fill="#F4F6F6"
        d="m105.429 51.49-.194 1.06c-1.657 8.932-7.773 16.092-16.065 18.368a20.76 20.76 0 0 1-3.258.623c-7.951.886-16.427-2.81-21.725-9.477-3.115-3.922-5.345-8.907-9.54-11.418-5.466-3.271-12.07-1.132-17.76 1.06-5.697 2.195-12.306 4.313-17.76 1.016-3.415-2.06-5.618-5.918-6.772-9.87-3.068-10.459.467-21.992 7.437-29.54C26.763 5.762 36.702 1.847 46.78.594c14.109-1.75 29.288 1.636 40.953 10.967 11.666 9.33 19.213 24.947 17.697 39.93Z"
      />
      <path
        fill="#14BAC6"
        fillRule="evenodd"
        d="M23.348 38.028v36.886a7.933 7.933 0 0 0 7.935 7.936h55.541a7.933 7.933 0 0 0 7.936-7.936V38.028"
        clipRule="evenodd"
      />
      <path
        stroke="#14BAC6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        d="M23.348 38.028v36.886a7.933 7.933 0 0 0 7.935 7.936h55.541a7.933 7.933 0 0 0 7.936-7.936V38.028"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        stroke="#14BAC6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        d="m26.864 31.445 27.75-18.667a7.962 7.962 0 0 1 8.883 0l27.749 18.667c4.674 3.147 4.674 10.022 0 13.169L67.94 60.294a15.91 15.91 0 0 1-17.766 0l-23.306-15.68c-4.678-3.147-4.678-10.026-.004-13.169Z"
        clipRule="evenodd"
      />
      <path
        stroke="#14BAC6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        d="M45.635 33.41h26.844M48.588 44.06h21.049"
      />
      <path
        fill="#E1E4E5"
        d="m14.111 61.435-6.088 6.09a.568.568 0 0 0 .803.802l6.088-6.089a.568.568 0 0 0-.803-.803ZM13.517 65.795 11.76 67.55a.568.568 0 1 0 .803.803l1.756-1.756a.568.568 0 0 0-.803-.803Z"
      />
      <mask id="a" fill="#fff">
        <path d="m107.793 27.39 5.689-5.69a.53.53 0 1 0-.75-.75l-5.69 5.69a.53.53 0 0 0 0 .75h.001a.53.53 0 0 0 .75 0Z" />
      </mask>
      <path
        fill="#E1E4E5"
        d="m107.793 27.39 5.689-5.69a.53.53 0 1 0-.75-.75l-5.69 5.69a.53.53 0 0 0 0 .75h.001a.53.53 0 0 0 .75 0Z"
      />
      <path
        fill="#0C1432"
        d="m107.825 16.043-5.689 5.69 11.314 11.314 5.689-5.69-11.314-11.314Zm-5.689 5.69a7.469 7.469 0 0 1 10.563 0l-11.313 11.314a8.53 8.53 0 0 0 12.064 0l-11.314-11.314Zm10.563 0-11.313 11.314 11.313-11.314Zm0 0a7.47 7.47 0 0 1 0 10.563l-11.313-11.313a8.53 8.53 0 0 0 0 12.064l11.313-11.314Zm0 10.563 5.69-5.69-11.314-11.313-5.689 5.69 11.313 11.313Zm5.69-5.69a7.47 7.47 0 0 1-10.564 0l11.314-11.313a8.53 8.53 0 0 0-12.064 0l11.314 11.314Zm-10.564 0v.001l11.314-11.314-11.314 11.314Zm0 .001a7.47 7.47 0 0 1 0-10.564l11.314 11.314a8.53 8.53 0 0 0 0-12.064l-11.314 11.314Z"
        mask="url(#a)"
      />
      <path
        fill="#E1E4E5"
        stroke="#0C1432"
        strokeWidth={8}
        d="M109.99 21.675a.53.53 0 1 0-.75-.75l-1.641 1.64a.53.53 0 1 0 .75.75l1.641-1.64Z"
      />
      <path
        stroke="#0C1432"
        strokeMiterlimit={10}
        strokeWidth={8}
        d="M8.527 55.602a2.264 2.264 0 1 1-4.528 0 2.264 2.264 0 0 1 4.528 0Z"
      />
      <path
        fill="#14BAC6"
        d="M119.032 38.071a3.744 3.744 0 1 1-7.233-1.937 3.744 3.744 0 0 1 7.233 1.937Z"
      />
      <path
        fill="#E1E4E5"
        d="M87.45 15.9c-.199.744-.853 1.214-1.46 1.051-.609-.163-.94-.897-.741-1.64.199-.743.853-1.214 1.461-1.05.608.162.94.897.74 1.64Z"
      />
      <path
        fill="#fff"
        d="m32.158 72.894-5.414 5.414a.505.505 0 1 0 .714.714l5.414-5.414a.505.505 0 1 0-.714-.714ZM36.59 72.894l-5.415 5.414a.505.505 0 1 0 .714.714l5.415-5.414a.505.505 0 1 0-.714-.714Z"
      />
    </svg>
  );
}
