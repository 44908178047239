import { useMemo, useState } from "react";
import type { ColumnDef } from "@tanstack/react-table";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useNavigate } from "react-router";
import { generatePath } from "react-router-dom";
import { t } from "ttag";

import type { Module } from "@/api/modules";
import { Chip, Pencil, Trash, Typography } from "@/components";
import { TableComponent } from "@/components/Table";
import { useModules } from "@/hooks/useQuery";
import { ROUTES } from "@/router";
import { Button, Modal } from "@/ui";
import { EmptyModules } from "./EmptyModules";

export const ModulesTable = ({ modules }: { modules: Module[] }) => {
  const { useDeleteModule } = useModules();
  const [deleteModuleModal, setDeleteModuleModal] = useState({
    open: false,
    selectedModule: 0,
  });

  const navigate = useNavigate();

  const onEdit = (row: Module) => {
    const path = generatePath(ROUTES.conceptsAndUseCases.moduleUnits, {
      id: row.id.toString(),
    });
    navigate(path);
  };

  const columns = useMemo<ColumnDef<Module>[]>(
    () => [
      {
        header: "Module Name",
        accessorFn: (row: Module) => row.name,
      },
      {
        header: "Description",
        accessorFn: (row: Module) => row.description ?? "-",
      },
      {
        header: "Units",
        accessorFn: (row: Module) => row.units.length ?? 0,
      },
      {
        id: "Users",
        header: "Users",
        cell: ({ row }) => (
          <Chip className="bg-gray-100" size="sm">
            <Typography className="text-xs text-main-blue" font="medium">
              {row.original.required_membership === "Premium"
                ? t`For members only`
                : t`For all users`}
            </Typography>
          </Chip>
        ),
      },
      {
        id: "actions",
        cell: ({ row }) => (
          <div className="flex flex-row items-center justify-end">
            <Button variant="transparent" onClick={() => onEdit(row.original)}>
              <Pencil className="fill-gray-500" />
            </Button>
            <Button
              variant="transparent"
              onClick={() => {
                setDeleteModuleModal({
                  open: true,
                  selectedModule: row.original.id,
                });
              }}
            >
              <Trash />
            </Button>
          </div>
        ),
      },
    ],
    [navigate],
  );

  const submitDelete = () => {
    useDeleteModule.mutate(deleteModuleModal.selectedModule, {
      onSuccess: () => {
        setDeleteModuleModal({ open: false, selectedModule: 0 });
      },
    });
  };

  const table = useReactTable({
    data: modules ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (modules.length === 0) {
    return <EmptyModules className="mt-28" />;
  }

  return (
    <div className="flex w-full grow flex-col self-start py-6">
      <TableComponent tableData={table} onClickRow={onEdit} />
      <Modal
        show={deleteModuleModal.open}
        className="py-4"
        title={t`Remove module`}
        description={t`Are you sure you want to remove the module?`}
        onClose={() => {
          setDeleteModuleModal({ open: false, selectedModule: 0 });
        }}
        descriptionClasses="text-gray font-base text-base"
      >
        <div className="flex flex-row">
          <Button
            variant="secondary"
            className="mr-1 w-1/2"
            disabled={useDeleteModule.isPending}
            onClick={() =>
              setDeleteModuleModal({ open: false, selectedModule: 0 })
            }
          >
            {t`Cancel`}
          </Button>
          <Button
            disabled={useDeleteModule.isPending}
            variant="primary"
            className="ml-1 w-1/2"
            onClick={submitDelete}
          >{t`Remove module`}</Button>
        </div>
      </Modal>
    </div>
  );
};
