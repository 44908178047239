import type { IconProps } from "./IconProps";

export function StarsModule({ className, ...props }: IconProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={126}
      height={92}
      fill="none"
      {...props}
    >
      <path
        fill="#EDF2F9"
        d="M106.733 60.911c.692 12.72-24.239 38.582-54.968 29.01-14.291-4.453-13.08-11.844-23.948-21.583-6.477-5.805-15.784-10.194-18.373-18.632-2.236-7.284-.865-15.112 3.012-20.204 8.719-11.451 26.321-19.292 43.664-1.25 17.344 18.042 48.824-.144 50.613 32.66Z"
      />
      <path
        fill="#EDF2F9"
        d="M95.252 28.61c-2.277 4.106-7.264 4.67-15.254 3.89-6.01-.588-11.49-1.043-17.499-4.354-4.206-2.316-7.536-5.449-9.966-8.492-2.633-3.297-6.31-7.06-4.507-10.797 2.476-5.135 16.796-9.46 30.701-2.387 15.275 7.77 18.753 18.125 16.525 22.14Z"
      />
      <path
        fill="#354863"
        fillRule="evenodd"
        d="M7.905 71.763a1.803 1.803 0 0 1-2.516-.902 1.79 1.79 0 0 1-.108-.982l1.301-7.51-5.51-5.322a1.793 1.793 0 0 1 1.001-3.055l7.619-1.096 3.402-6.839a1.81 1.81 0 0 1 1.622-.999 1.817 1.817 0 0 1 1.622 1l3.403 6.838 7.619 1.096a1.801 1.801 0 0 1 1.45 1.218 1.79 1.79 0 0 1-.449 1.837l-5.514 5.318 1.302 7.51a1.79 1.79 0 0 1-.72 1.762 1.802 1.802 0 0 1-1.904.126l-6.811-3.543-6.81 3.543Zm85.684 0a1.803 1.803 0 0 1-2.515-.902 1.79 1.79 0 0 1-.109-.982l1.302-7.51-5.511-5.322a1.793 1.793 0 0 1 1.001-3.055l7.619-1.096 3.403-6.839a1.81 1.81 0 0 1 1.622-.999 1.82 1.82 0 0 1 1.622 1l3.403 6.838 7.618 1.096a1.795 1.795 0 0 1 1.488 2.2 1.8 1.8 0 0 1-.487.855l-5.513 5.318 1.301 7.51a1.798 1.798 0 0 1-.721 1.762 1.8 1.8 0 0 1-1.903.126l-6.811-3.543-6.81 3.543Z"
        clipRule="evenodd"
      />
      <path
        fill="#14BAC6"
        d="M121.983 57.611c-1.923 0-3.482 1.462-3.482 3.265 0 1.803 1.559 3.265 3.482 3.265 1.923 0 3.482-1.462 3.482-3.265 0-1.803-1.559-3.265-3.482-3.265ZM6.846 32.364a3.265 3.265 0 1 1 0-6.53 3.265 3.265 0 0 1 0 6.53ZM78.453 20.61a3.047 3.047 0 1 1 0-6.093 3.047 3.047 0 0 1 0 6.094Z"
      />
      <path
        fill="#fff"
        d="M9.54 68.285a1.42 1.42 0 1 1-2.745.735 1.42 1.42 0 0 1 2.744-.735Z"
      />
      <path
        fill="#354863"
        d="M36.446 23.223a1.959 1.959 0 1 1 0-3.918 1.959 1.959 0 0 1 0 3.918Z"
      />
      <path
        fill="#fff"
        d="M111.086 57.484c-.868 0-1.571-.563-1.571-1.258 0-.694.703-1.257 1.571-1.257s1.572.563 1.572 1.258c0 .694-.704 1.257-1.572 1.257Z"
      />
      <path
        fill="#354863"
        d="M72.399 3.569a2.751 2.751 0 1 1-5.315 1.424 2.751 2.751 0 0 1 5.315-1.424Z"
      />
      <path
        fill="#14BAC6"
        fillRule="evenodd"
        d="M45.266 68.541a3.234 3.234 0 0 1-4.514-1.617 3.212 3.212 0 0 1-.194-1.764l2.335-13.48-9.89-9.55a3.218 3.218 0 0 1 1.798-5.483l13.672-1.968 6.107-12.274a3.25 3.25 0 0 1 2.911-1.794 3.26 3.26 0 0 1 2.912 1.794l6.107 12.274 13.672 1.968a3.233 3.233 0 0 1 2.602 2.185 3.212 3.212 0 0 1-.805 3.297l-9.895 9.546 2.335 13.48a3.213 3.213 0 0 1-1.293 3.16 3.234 3.234 0 0 1-3.415.227l-12.225-6.358-12.22 6.358Z"
        clipRule="evenodd"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4.87}
        d="m63.35 43.03-7.245 6.958-4.352-4.175"
      />
    </svg>
  );
}
