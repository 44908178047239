import { ChevronLeftIcon } from "@heroicons/react/24/outline";

import { Button } from "@/ui";

export const PreviewHeader = ({ close }: { close: () => void }) => (
  <header className="relative flex h-20 w-full items-center justify-between bg-slate-900 px-2 text-white">
    <Button
      className="flex items-center gap-3 font-light"
      onClick={close}
      variant="transparent"
    >
      <ChevronLeftIcon className="size-5" />
      <h2 className="text-lg">Back to editor</h2>
    </Button>
  </header>
);
