import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import type { Data } from "@measured/puck";
import { usePuck } from "@measured/puck";
import { t } from "ttag";

import type { Unit } from "@/api/units";
import { Button } from "@/ui";

export const PuckHeader = ({
  unit,
  onPublish,
  setPreviewingData,
  onBackClick,
}: {
  unit: Unit;
  onPublish: (data: Data) => void;
  setPreviewingData: (value: Data) => void;
  onBackClick: (data: Data) => void;
}) => {
  const { appState, dispatch } = usePuck();

  return (
    <button
      className="relative flex h-20 w-full items-center justify-between bg-slate-900 px-6 py-4 text-white"
      onClick={() => dispatch({ type: "setUi", ui: { itemSelector: null } })}
    >
      <button
        className="flex items-center gap-3"
        onClick={() => onBackClick(appState.data)}
      >
        <ChevronLeftIcon className="size-5" />
        <h2 className="text-lg">
          <span className="font-semibold">Unit - </span>
          {unit.name}
        </h2>
      </button>
      <div className="flex items-center gap-6">
        <Button
          className="w-52 border border-white/80 font-normal text-white hover:border-white/100"
          variant="transparent"
          onClick={() => onPublish(appState.data)}
        >
          {t`Save and Publish`}
        </Button>
        <Button
          className="w-40 border border-white/80 font-normal text-white hover:border-white/100"
          variant="transparent"
          onClick={() => {
            dispatch({ type: "setData", data: appState.data });
            setPreviewingData(appState.data);
          }}
        >
          Preview
        </Button>
      </div>
    </button>
  );
};
