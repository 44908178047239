import type { Field } from "@measured/puck";
import { FieldLabel } from "@measured/puck";

export const transformSliderValue = (
  value: number,
  min: number,
  max: number,
) => {
  return (value * (max - min)) / 100 + min;
};

export const SliderField = ({
  field,
  onChange,
  value,
}: {
  field: Field;
  onChange: (value: number) => void;
  value: number;
}) => (
  <FieldLabel label={field.label ?? ""}>
    <div className="flex gap-4">
      <input
        type="number"
        defaultValue={20}
        onChange={(e) => {
          let value = parseInt(e.target.value);
          if (value > 100) {
            value = 100;
          }
          if (value < 1) {
            value = 1;
          }
          onChange(value);
        }}
        value={value}
        className="w-14 rounded border indent-3 [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
      />
      <input
        type="range"
        min={1}
        max={100}
        onChange={(e) => onChange(parseInt(e.target.value))}
        value={value}
        className="w-full"
      />
    </div>
  </FieldLabel>
);
