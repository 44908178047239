export const ROUTES = {
  base: "/",
  login: "/login",
  home: "/concepts-and-use-cases",
  forgotPassword: {
    forgotPassword: "/forgot-password",
    confirmation: "/forgot-password/confirmation",
  },
  resetPassword: {
    resetPassword: "/reset-password",
    confirmation: "/reset-password/confirmation",
  },
  setPassword: {
    setPassword: "/set-password",
    confirmation: "/set-password/confirmation",
  },
  conceptsAndUseCases: {
    base: "/concepts-and-use-cases",
    createModule: "/concepts-and-use-cases/create-module",
    moduleUnits: "/concepts-and-use-cases/:id/units",
    editUnitSlides: "/concepts-and-use-cases/units/:unitId/slides/edit",
    viewUnitSlides: "/concepts-and-use-cases/units/:unitId/slides",
  },
  adminUsersManagement: "/managements",
  modules: {
    base: "/modules",
    moduleUnits: "/modules/:id/units",
    unitSlides: "/modules/:id/units/:unitId/slides",
  },
  usersManagement: {
    base: "/users-management",
  },

  notFound: "*",
} as const;

export const MODAL_ROUTES = {
  exampleModal: "/example-modal",
  userForm: "/user-form",
  createUnitSlide: "/units/:unitId/create-slide",
} as const;
