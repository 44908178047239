import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { CheckInCircle } from "@/components/icons";
import { Typography } from "@/components/Typography";
import { ROUTES } from "@/router";
import { Button } from "@/ui";
import { tw } from "@/utils";
import { AuthLogo } from "./AuthLogo";

export const ForgotPasswordConfirmation = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-1 grow flex-row items-center justify-center">
      <AuthLogo />
      <div className="flex h-screen w-1/2 grow flex-col items-center justify-center bg-white">
        <div className="flex w-auto flex-col items-center md:w-96">
          <CheckInCircle />
          <Typography
            className="mt-6 text-center text-2xl text-gray-700"
            font="medium"
          >
            {t`Email successfully sent!`}
          </Typography>
          <Typography className="mb-6 mt-3 text-center text-base text-gray">
            {t`A link to reset your password has been sent to your email. Please check your inbox.`}
          </Typography>
          <Button
            variant="transparent"
            className={tw("text-gray underline")}
            onClick={() => navigate(ROUTES.login)}
          >
            {t`Back to log in`}
          </Button>
        </div>
      </div>
    </div>
  );
};
