import type { ComponentPropsWithoutRef } from "react";

import { tw } from "@/utils";

const typographyVariants = {
  base: "text-base",
  detail: "text-xs",
  large: "text-lg",
  small: "text-sm",
  xl: "text-xl",
} as const;

export type TypographyVariant = keyof typeof typographyVariants;

export const typographyFonts = {
  medium: "font-medium",
  regular: "font-regular",
  semiBold: "font-semibold",
  bold: "font-bold",
} as const;

export type TypographyFont = keyof typeof typographyFonts;

export const typographyTypes = ["title", "regular"] as const;
export type TypographyType = (typeof typographyTypes)[number];

export interface TypographyProps extends ComponentPropsWithoutRef<"p"> {
  as?: "span";
  font?: TypographyFont;
  type?: TypographyType;
  variant?: TypographyVariant;
}

export const Typography = ({
  as: Container = "span",
  children,
  className,
  font = "regular",
  type,
  variant = "base",
  ...props
}: TypographyProps) => (
  <Container
    className={tw(
      "text-primary-chocolate-plum tracking-normal",
      typographyVariants[variant],
      typographyFonts[font],
      type === "title" && "font-Inter",
      className,
    )}
    {...props}
  >
    {children}
  </Container>
);
