import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { generatePath } from "react-router-dom";

import { MODAL_ROUTES } from "@/router";
import { useNavigateModal } from "@/router/useNavigateModal";

export const SlideList = ({
  unitId,
  children,
}: {
  unitId: number;
  children?: React.ReactNode;
}) => {
  const navigateModal = useNavigateModal();

  const onCreate = () => {
    const path = generatePath(MODAL_ROUTES.createUnitSlide, {
      unitId: unitId.toString(),
    }) as (typeof MODAL_ROUTES)["createUnitSlide"];

    navigateModal(path);
  };

  return (
    <div className="flex h-full flex-col gap-4 overflow-hidden ">
      <h2 className="text-xl font-semibold text-gray-500">Slides</h2>
      <div className="flex flex-col gap-4 overflow-y-scroll ">
        {children}
        <button
          className="flex aspect-video w-full shrink-0 cursor-pointer flex-col justify-center border-4 border-dashed"
          onClick={onCreate}
          onKeyDown={onCreate}
        >
          <PlusCircleIcon className="mx-auto size-12 text-gray-200" />
        </button>
      </div>
    </div>
  );
};
