import type { Slide } from "@/api";
import { tw } from "@/utils";

export const SlideSquare = ({
  onClick,
  selected,
  position,
  slide,
}: {
  onClick: () => void;
  selected: boolean;
  position: number;
  slide: Slide;
}) => {
  return (
    <button
      className={tw(
        "flex aspect-video w-full shrink-0 cursor-pointer flex-col items-center justify-between border-4 py-2",
        selected && "border-blue-500",
      )}
      onClick={onClick}
    >
      <div className="h-3"></div>
      <h2 className="w-full text-center text-2xl text-gray-500">
        {position + 1}
      </h2>
      <p className="h-3 text-xs text-gray-500">{slide.name}</p>
    </button>
  );
};
