import { t } from "ttag";

import { getRootSize } from "../../utils";
import { AvailableFields } from "../customFields";
import { transformSliderValue } from "../customFields/Slider";

const HeadingComponent = ({
  content,
  fontSize,
}: {
  content: string;
  fontSize: number;
}) => {
  const rootWidth = getRootSize().width;

  fontSize = transformSliderValue(fontSize, 20, 100);

  const fontSizePx = `${(fontSize / 1000) * rootWidth}px`;

  return (
    <h1 style={{ fontSize: fontSizePx }} className="font-semibold">
      {content}
    </h1>
  );
};

export const HeadingConfig = {
  itemRender: (
    <p className="py-1 text-center text-3xl font-semibold text-gray-500">
      {t`Heading`}
    </p>
  ),
  label: "Text",
  fields: {
    content: {
      type: "text",
      label: t`Text content`,
    },
    fontSize: {
      type: "custom",
      label: t`Font Size`,
      render: AvailableFields.slider,
    },
  },
  defaultProps: {
    content: `Heading Text`,
    fontSize: 20,
  },
  render: HeadingComponent,
};
