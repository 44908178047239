import type { ServiceResponse } from "./api.types";
import { getApi } from "./axios";
import type { Unit } from "./units";

export type ModuleMembership = "Free" | "Premium";

export interface Module {
  id: number;
  name: string;
  description: string;
  units: Unit[];
  available: boolean;
  required_membership: ModuleMembership;
}

interface CreateModuleParams {
  name: string;
  description: string;
  required_membership: ModuleMembership;
}

interface UpdateModuleParams {
  id: number;
  name: string;
  description: string;
  required_membership: ModuleMembership;
  required_module?: number;
}

export const getModules = async () => {
  const { data } = await getApi().get<ServiceResponse<Module[]>>("/modules");
  return data.data;
};

export const getModule = async (id: number) => {
  const { data } = await getApi().get<ServiceResponse<Module>>(
    `/modules/${id}`,
  );
  return data.data;
};

export const createModule = async (params: CreateModuleParams) => {
  const data = await getApi().post<ServiceResponse<Module>>("/modules", params);
  return data;
};

export const deleteModule = async (id: number) => {
  const data = await getApi().delete(`/modules/${id}`);
  return data;
};

export const updateModule = async (params: UpdateModuleParams) => {
  const data = await getApi().put(`/modules/${params.id}`, params);
  return data;
};
