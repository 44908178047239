import type { CSSProperties, ReactNode } from "react";

export interface SectionProps {
  className?: string;
  children: ReactNode;
  padding?: string;
  maxWidth?: string;
  style?: CSSProperties;
}

export const Section = ({
  children,
  className,
  padding = "0px",
  maxWidth = "1280px",
  style = {},
}: SectionProps) => {
  return (
    <div
      style={{
        ...style,
        paddingTop: padding,
        paddingBottom: padding,
      }}
      className={className}
    >
      <div style={{ maxWidth, height: "100%" }}>{children}</div>
    </div>
  );
};
