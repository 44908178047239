import type { IconProps } from "./IconProps";

export function Document({ className, ...props }: IconProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={19}
      height={22}
      fill="none"
      {...props}
    >
      <path
        fill="#354863"
        d="M4.427 21v-1 1Zm9.6 0v1-1Zm3.2-3.2h-1 1Zm0-13.6h1-1Zm-.218-2.108.89-.454-.89.454ZM14.027 1V0v1Zm2.107.218.454-.891-.454.891Zm0 19.564.454.891-.454-.891Zm.875-.874-.891-.454.89.454Zm-14.69.874.454-.891-.454.891ZM1.227 17.8h-1 1Zm.218 2.108.89-.454-.89.454ZM2.227 7a1 1 0 1 0-2 0h2Zm5-7a1 1 0 0 0 0 2V0Zm-5.83 6.83L.69 6.121l.707.707Zm5.659-5.66.707.708-.707-.707ZM1.468 7v1-1Zm4.759 0V6v1Zm1-1h-1 1Zm0-4.759h1-1Zm-2 8.759a1 1 0 1 0 0 2v-2Zm8 2a1 1 0 0 0 0-2v2Zm-2-6a1 1 0 1 0 0 2V6Zm2 2a1 1 0 0 0 0-2v2Zm-8 6a1 1 0 1 0 0 2v-2Zm8 2a1 1 0 0 0 0-2v2Zm-8.8 6h9.6v-2h-9.6v2Zm13.8-4.2V4.2h-2v13.6h2Zm0-13.6c0-.544 0-1.011-.031-1.395-.032-.395-.104-.789-.296-1.167l-1.782.908c.025.05.062.15.085.422.023.283.024.655.024 1.232h2Zm-4.2-2.2c.576 0 .948 0 1.232.024.271.022.372.06.422.085l.908-1.782c-.379-.193-.772-.264-1.167-.296C15.038-.001 14.57 0 14.027 0v2Zm3.873-.362A3 3 0 0 0 16.589.327l-.908 1.782a1 1 0 0 1 .437.437l1.782-.908ZM14.027 22c.543 0 1.01 0 1.395-.03.395-.033.788-.104 1.166-.297l-.907-1.782c-.05.025-.15.063-.422.085-.284.023-.656.024-1.232.024v2Zm2.2-4.2c0 .577-.001.949-.024 1.232-.023.272-.06.372-.085.422l1.782.908c.192-.378.264-.772.296-1.167.031-.384.03-.852.03-1.395h-2Zm.361 3.873a3 3 0 0 0 1.312-1.311l-1.782-.908a1 1 0 0 1-.437.437l.908 1.782ZM4.428 20c-.577 0-.95 0-1.233-.024-.271-.022-.372-.06-.421-.085l-.908 1.782c.378.193.771.264 1.167.296.383.032.851.031 1.395.031v-2Zm-4.2-2.2c0 .544-.001 1.011.03 1.395.032.395.104.789.297 1.167l1.782-.908c-.026-.05-.063-.15-.086-.422-.023-.283-.023-.655-.023-1.232h-2Zm2.546 2.091a1 1 0 0 1-.437-.437l-1.782.908a3 3 0 0 0 1.31 1.311l.909-1.782ZM.227 7v10.8h2V7h-2Zm13.8-7h-6.8v2h6.8V0ZM2.104 7.536l5.659-5.658L6.349.464.69 6.122l1.414 1.414ZM1.468 8h4.759V6H1.468v2Zm6.759-2V1.241h-2V6h2Zm-2 2a2 2 0 0 0 2-2h-2v2Zm1.536-6.122c-.567.567-1.536.165-1.536-.637h2c0-.98-1.185-1.47-1.878-.777l1.414 1.414ZM.69 6.122C-.003 6.815.488 8 1.468 8V6c.802 0 1.203.97.636 1.536L.69 6.122ZM5.227 12h8v-2h-8v2Zm6-4h2V6h-2v2Zm-6 8h8v-2h-8v2Z"
      />
    </svg>
  );
}
