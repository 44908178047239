import { t } from "ttag";

import type { Unit } from "@/api";
import { Lock, Typography } from "@/components";
import { Button } from "@/ui";
import { tw } from "@/utils";

interface UnitsListInterface {
  units: Unit[];
  onSelect: (unitId: number) => void;
  classes?: string;
}

export const UnitsList = ({
  units = [],
  onSelect,
  classes,
}: UnitsListInterface) => {
  if (units.length === 0) {
    return (
      <div className="mt-40 flex items-center justify-center">
        <Typography
          font="semiBold"
          variant="xl"
          className="text-main-blue"
        >{t`This module has no units yet`}</Typography>
      </div>
    );
  }
  return (
    <div className={tw("my-10 flex w-full grow flex-col self-start", classes)}>
      {units.map((unit) => (
        <div
          key={unit.id}
          className={tw(
            "mb-2 flex h-20 w-full items-center justify-between rounded-xl border border-light-base p-5",
            !unit.available && "bg-gray-100",
          )}
        >
          <Typography className="text-lg text-gray-500" font="semiBold">
            {`${unit.name} ${unit.description ? " - " + unit.description : ""}`}
          </Typography>
          {unit.available ? (
            <div className="flex items-center gap-4">
              {unit.slides && unit.slides.length === 0 && (
                <Typography className="text-sm text-gray-500/70">
                  {t`Unit has no content yet`}
                </Typography>
              )}
              {unit.slides && unit.slides.length === 0 && unit.completed && (
                <Typography className="text-sm text-gray-500/70">-</Typography>
              )}
              {unit.completed && (
                <Typography className="text-sm text-gray-500/70">
                  {t`Completed`}
                </Typography>
              )}
              <Button
                variant="tertiary"
                disabled={unit.slides && unit.slides.length === 0}
                onClick={() => onSelect(unit.id)}
              >
                {t`Start`}
              </Button>
            </div>
          ) : (
            <Lock className="stroke-gray-500" />
          )}
        </div>
      ))}
    </div>
  );
};
