import { ToastMessage } from "./ToastMessage";
import { useToastStore } from "./toastStore";

export const Toasts = () => {
  const { toasts, deleteToast } = useToastStore();

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none absolute inset-0 z-50 flex items-end overflow-hidden px-4 py-6 sm:items-start sm:p-6"
    >
      <div className="flex size-full flex-col items-center justify-end space-y-4 sm:items-end">
        {toasts.map((toast) => (
          <ToastMessage
            key={toast.id}
            toast={toast}
            onClose={() => void deleteToast(toast.id)}
          />
        ))}
      </div>
    </div>
  );
};
