import type { IconProps } from "./IconProps";

export function EyeClosedIcon({ className }: IconProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={18}
      fill="none"
    >
      <path
        strokeWidth={0.5}
        d="m1.91 7.828.908.418-.909-.418Zm18.181 2.344-.908-.418.908.418Zm-18.181 0-.91.417.91-.417ZM20.09 7.828l-.908.418.908-.418Zm-3.123-5.07a1 1 0 0 0-1.086 1.68l1.086-1.68ZM6.162 13.59a1 1 0 1 0-1.077 1.685l1.077-1.685ZM2.2 12.602A1 1 0 0 0 3.8 11.4l-1.6 1.202ZM12.8 3.18a1 1 0 1 0 .398-1.96l-.397 1.96Zm6.906-1.473A1 1 0 0 0 18.293.293l1.415 1.414ZM2.293 16.293a1 1 0 1 0 1.415 1.414l-1.415-1.414ZM11 1A11.002 11.002 0 0 0 1 7.41l1.818.836A9.002 9.002 0 0 1 11 3V1Zm0 16c4.44 0 8.262-2.629 10-6.41l-1.817-.836A9.002 9.002 0 0 1 11 15v2ZM1 7.41a3.808 3.808 0 0 0 0 3.18l1.818-.836a1.808 1.808 0 0 1 0-1.508L1.001 7.41Zm18.183.836c.22.478.22 1.03 0 1.508L21 10.59a3.808 3.808 0 0 0 0-3.178l-1.817.835ZM21 7.41a11.042 11.042 0 0 0-4.032-4.653l-1.086 1.68a9.041 9.041 0 0 1 3.3 3.808L21 7.41ZM5.085 15.275A10.953 10.953 0 0 0 11 17v-2a8.953 8.953 0 0 1-4.838-1.41l-1.077 1.685ZM1 10.59c.329.716.732 1.39 1.2 2.013L3.8 11.4a8.996 8.996 0 0 1-.982-1.646l-1.817.835ZM13.199 1.22A11.047 11.047 0 0 0 11 1v2c.618 0 1.22.062 1.802.18l.397-1.96ZM8.172 11.828a4 4 0 0 0 5.657 0l-1.414-1.414a2 2 0 0 1-2.829 0l-1.414 1.414Zm5.657 0a4 4 0 0 0 0-5.656l-1.414 1.414a2 2 0 0 1 0 2.828l1.414 1.414ZM18.293.293l-16 16 1.415 1.414 16-16L18.293.293Z"
      />
    </svg>
  );
}
