import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { deleteUser, getUser, getUsers, updateUser } from "@/api";
import { errorToast } from "@/ui";

export const usersQueryKeys = {
  getUsersQuery: () => ["getUsersQuery"],
  getUserQuery: (id: number) => ["getUserQuery", id],
};

export const useUsers = () => {
  const queryClient = useQueryClient();

  const useGetUsers = () =>
    useQuery({
      queryKey: usersQueryKeys.getUsersQuery(),
      queryFn: getUsers,
    });

  const useGetUser = (invitationId: number) =>
    useQuery({
      queryKey: usersQueryKeys.getUserQuery(invitationId),
      queryFn: () => getUser(invitationId),
    });

  const useDeleteUser = useMutation({
    mutationFn: deleteUser,
    onError: (e) => {
      errorToast(e);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: usersQueryKeys.getUsersQuery(),
      });
    },
  });

  const useUpdateUser = useMutation({
    mutationFn: updateUser,
    onError: (e) => {
      errorToast(e);
    },
    onSuccess: (_, params) => {
      void queryClient.invalidateQueries({
        queryKey: usersQueryKeys.getUserQuery(params.id),
      });
      void queryClient.invalidateQueries({
        queryKey: usersQueryKeys.getUsersQuery(),
      });
    },
  });

  return {
    useGetUsers,
    useGetUser,
    useDeleteUser,
    useUpdateUser,
  };
};
