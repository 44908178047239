import { useState } from "react";
import type { Data } from "@measured/puck";
import { Puck, Render } from "@measured/puck";
import { useNavigate, useParams } from "react-router-dom";
import { t } from "ttag";

import { Spinner } from "@/components/animations/Spinner";
import { useSlides } from "@/hooks/useQuery/useSlides";
import { useUnits } from "@/hooks/useQuery/useUnits";
import { ExitSlideModal } from "@/modals/Slide/ExitSlideModal";
import { useToastStore } from "@/ui";
import { CustomPuckUi } from "./components/CustomPuckUi";
import { PreviewHeader } from "./components/PreviewHeader/PreviewHeader";
import { PuckHeader } from "./components/PuckHeader/PuckHeader";
import { puckConfig, puckEditorProps } from "./utils";

export function Editor() {
  const { unitId } = useParams();
  const { useUpdateSlide } = useSlides();
  const { useGetUnit } = useUnits();
  const navigate = useNavigate();

  const [previewingData, setPreviewingData] = useState<Data | null>(null);
  const [selectedSlide, setSelectedSlide] = useState<number | null>(null);
  const [discardAction, setDiscardAction] = useState<() => void>();
  const [saveAction, setSaveAction] = useState<() => void>();

  const { pushToast } = useToastStore((state) => state);

  const unit = useGetUnit(Number(unitId));

  const onPublish = (data: Data) => {
    if (!unit.data) {
      return;
    }

    if (!selectedSlide) {
      return;
    }

    useUpdateSlide.mutate(
      {
        slideId: selectedSlide,
        params: {
          data,
          files: [],
        },
      },
      {
        onSuccess: () => {
          void pushToast({
            type: "success",
            title: t`Slide Published`,
            message: t`Slide has been successfully published`,
          });
        },
        onError: () => {
          void pushToast({
            type: "error",
            title: t`Slide Publish Error`,
            message: t`An error occurred while publishing the slide`,
          });
        },
      },
    );
  };

  const handleSelectSlide = (
    selectSlideFunction: () => {
      nextSlideId: number;
      currentSlideData: Data;
    },
  ) => {
    if (selectedSlide) {
      setDiscardAction(
        () => () => setSelectedSlide(selectSlideFunction().nextSlideId),
      );
      setSaveAction(() => () => {
        const { nextSlideId, currentSlideData } = selectSlideFunction();
        setSelectedSlide(nextSlideId);
        onPublish(currentSlideData);
      });
    } else {
      setSelectedSlide(selectSlideFunction().nextSlideId);
    }
  };

  return (
    <>
      <Puck {...puckEditorProps}>
        {previewingData ? (
          <div className="fixed inset-0 flex h-full flex-col bg-gray-50">
            <PreviewHeader close={() => setPreviewingData(null)} />
            <div className="mx-auto flex w-4/5 flex-1 items-center justify-center">
              <div className="mx-auto w-fit bg-white">
                <Render data={previewingData} config={puckConfig} />
              </div>
            </div>
          </div>
        ) : unit.isPending || unit.isError ? (
          <div className="fixed flex size-full justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="flex h-screen flex-col">
            <PuckHeader
              onPublish={onPublish}
              unit={unit.data}
              setPreviewingData={setPreviewingData}
              onBackClick={(data) => {
                setDiscardAction(() => () => navigate(-1));
                setSaveAction(() => () => {
                  onPublish(data);
                  navigate(-1);
                });
              }}
            />
            <CustomPuckUi
              unit={unit.data}
              setSelectedSlide={handleSelectSlide}
              selectedSlide={selectedSlide}
            />
          </div>
        )}
      </Puck>
      <ExitSlideModal
        show={!!saveAction}
        onClose={() => {
          setDiscardAction(undefined);
          setSaveAction(undefined);
        }}
        onDiscard={() => {
          discardAction && discardAction();
          setDiscardAction(undefined);
          setSaveAction(undefined);
        }}
        onSubmit={() => {
          saveAction && saveAction();
          setDiscardAction(undefined);
          setSaveAction(undefined);
        }}
      />
    </>
  );
}
