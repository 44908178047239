import { useEffect, useState } from "react";
import type { Data } from "@measured/puck";
import { Puck, usePuck } from "@measured/puck";

import type { Slide } from "@/api/slides";
import type { Unit } from "@/api/units";
import { Tabs } from "@/components";
import { Button } from "@/ui";
import { tw } from "@/utils";
import { SlideList } from "./SlideList";
import { SlideSquare } from "./SlideSquare";

export const CustomPuckUi = ({
  unit,
  selectedSlide,
  setSelectedSlide,
}: {
  unit: Unit;
  selectedSlide: number | null;
  setSelectedSlide: (
    setSlideFunction: () => {
      nextSlideId: number;
      currentSlideData: Data;
    },
  ) => void;
}) => {
  const { dispatch, appState } = usePuck();
  const tabs = {
    Components: <Puck.Components />,
    Properties: <Puck.Fields />,
  } as const;

  type Tabs = keyof typeof tabs;

  const [activeTab, setActiveTab] = useState<Tabs>("Components");

  const onSlideClick = (slide: Slide) => {
    setSelectedSlide(() => {
      dispatch({ type: "setUi", ui: { itemSelector: null } });
      dispatch({ type: "setData", data: slide.data });
      return {
        nextSlideId: slide.id,
        currentSlideData: appState.data,
      };
    });
  };

  useEffect(() => {
    if (!selectedSlide && unit.slides[0]) {
      onSlideClick(unit.slides[0]);
    }
  }, [unit.slides, selectedSlide]);

  return (
    <div className="flex h-[calc(100%_-_80px)] bg-gray-100">
      <div className="flex w-1/6 flex-col gap-6 overflow-y-scroll bg-white p-4">
        <SlideList unitId={unit.id}>
          {unit.slides.map((slide, idx) => (
            <SlideSquare
              key={slide.id}
              onClick={() => onSlideClick(slide)}
              selected={slide.id === selectedSlide}
              position={idx}
              slide={slide}
            />
          ))}
        </SlideList>
      </div>
      <div className="flex h-full w-4/6 flex-col justify-center bg-gray-50">
        {selectedSlide && (
          <div
            id="puck-root"
            className="dashed mx-auto aspect-video w-4/5 overflow-hidden rounded bg-gray-100/90 p-2"
          >
            <Puck.Preview />
          </div>
        )}
      </div>
      <div className="h-full w-1/6 overflow-y-scroll bg-white">
        <Tabs
          className="flex w-full justify-between"
          tabs={Object.keys(tabs) as Tabs[]}
          value={activeTab}
          onChange={(status) => {
            setActiveTab(status);
          }}
          renderTab={({ tab, onClick, selected }) => (
            <Button
              key={tab}
              variant="transparent"
              className={tw(
                "w-full rounded-none border-b border-b-gray-200 px-4 text-base font-normal text-gray-300",
                selected && "border-b-gray bg-gray-40 font-semibold text-gray",
              )}
              onClick={onClick}
            >
              {tab}
            </Button>
          )}
        />
        <div className="flex flex-col items-center px-5 py-2 child:w-full">
          {tabs[activeTab]}
        </div>
      </div>
    </div>
  );
};
