import { useEffect, useState } from "react";
import { Render } from "@measured/puck";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { t } from "ttag";

import UnitCompleted from "@/assets/UnitCompleted.svg";
import { Document, Typography } from "@/components";
import { useUnits } from "@/hooks/useQuery";
import { ROUTES } from "@/router";
import { useUserStore } from "@/stores";
import { Button, errorToast, useToastStore } from "@/ui";
import { tw } from "@/utils";
import { puckConfig } from "../Editor/utils";

export const SlideViewer = () => {
  const { unitId, id: moduleId } = useParams();
  const { user } = useUserStore();

  const { pushToast } = useToastStore();

  const navigate = useNavigate();
  const [finishScreen, setFinishScreen] = useState(false);
  const [selectedSlideIndex, setSelectedSlideIndex] = useState<number | null>(
    null,
  );

  const { useGetUnit, useCompleteUnit } = useUnits();
  const unit = useGetUnit(Number(unitId));

  useEffect(() => {
    if (unit.data?.slides[0] && !selectedSlideIndex) {
      setSelectedSlideIndex(0);
    }
  }, [unit.data]);

  const selectedSlide =
    selectedSlideIndex != null ? unit.data?.slides[selectedSlideIndex] : null;

  const handeSubmit = () => {
    if (!user) {
      return;
    }
    useCompleteUnit.mutate(
      {
        userId: user.id,
        unitId: Number(unitId),
      },
      {
        onSuccess: () => {
          void pushToast({
            type: "success",
            title: t`Success`,
            message: t`Unit completed successfully`,
          });
          handleNavigateToUnits();
        },
        onError: () => {
          errorToast(t`Failed to complete unit`);
        },
      },
    );
  };

  const handleNext = () => {
    if (
      unit.data?.slides.length &&
      selectedSlideIndex === unit.data?.slides.length - 1 &&
      !finishScreen
    ) {
      setFinishScreen(true);
      return;
    }
    if (finishScreen) {
      handeSubmit();
      return;
    }
    setSelectedSlideIndex((prev) =>
      prev != null && prev < (unit.data?.slides.length ?? 0) - 1
        ? prev + 1
        : prev,
    );
  };

  const handleNavigateToUnits = () => {
    if (moduleId) {
      const path = generatePath(ROUTES.modules.moduleUnits, {
        id: moduleId,
      });
      navigate(path);
    } else {
      navigate(`/`);
    }
    return null;
  };

  if (!unit.isLoading && (!unit.data || unit.data.slides.length == 0)) {
    handleNavigateToUnits();
  }

  return (
    <div className="flex h-screen flex-col justify-between bg-gray-100">
      <div className="flex h-16 w-full items-center justify-between bg-white px-8">
        <div className="flex items-center gap-6">
          <Document />
          <Typography variant="xl">{unit.data?.name}</Typography>
        </div>
        <Button
          variant="transparent"
          className="text-2xl font-light"
          onClick={handleNavigateToUnits}
        >
          X
        </Button>
      </div>
      <div className="flex h-[calc(100%_-_168px)] flex-col justify-center">
        <div
          id="puck-root"
          className={tw(
            "dashed mx-auto my-12 aspect-video h-full overflow-hidden",
            finishScreen && "flex flex-col items-center justify-center",
          )}
        >
          {finishScreen ? (
            <img src={UnitCompleted} alt="Completed" />
          ) : (
            selectedSlide && (
              <Render config={puckConfig} data={selectedSlide.data} />
            )
          )}
        </div>
      </div>
      <div className="h-2 bg-gray-200">
        <div
          className="h-2 bg-healthtech-blue"
          style={{
            width: `${
              finishScreen
                ? 100
                : ((selectedSlideIndex ?? 0) /
                    (unit.data?.slides.length ?? 1)) *
                  100
            }%`,
          }}
        ></div>
      </div>
      <div className="flex h-24 items-center justify-between bg-slate-900 px-10 py-5">
        <Typography className="text-white">
          {(selectedSlideIndex ?? 0) + 1} of {unit.data?.slides.length} slides -{" "}
          {unit.data?.slides[selectedSlideIndex ?? 0]?.name}
        </Typography>
        <div className="flex items-center justify-between gap-4">
          <Button
            variant="outline"
            className={tw(
              "h-14 w-40 bg-white text-gray-500 hover:bg-gray-50",
              selectedSlideIndex === null || selectedSlideIndex === 0
                ? "cursor-not-allowed bg-gray-50 text-gray-200"
                : "",
            )}
            disabled={selectedSlideIndex === null || selectedSlideIndex === 0}
            onClick={() => {
              setSelectedSlideIndex((prev) =>
                prev != null && prev > 0 ? prev - 1 : prev,
              );
              setFinishScreen(false);
            }}
          >
            {t`Previous`}
          </Button>
          <Button
            className="h-14 w-40"
            disabled={selectedSlideIndex === null || !unit.data?.slides.length}
            onClick={handleNext}
          >
            {finishScreen ? t`Finish` : t`Next`}
          </Button>
        </div>
      </div>
    </div>
  );
};
