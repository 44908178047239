import { useEffect, useState } from "react";
import { PlayCircleIcon } from "@heroicons/react/24/outline";
import type { AxiosResponse } from "axios";
import { t } from "ttag";

import type { ServiceResponse } from "@/api/api.types";
import { getApi } from "@/api/axios";
import { AvailableFields } from "../customFields";

const alignValues = {
  left: "mr-auto",
  center: "mx-auto",
  right: "ml-auto",
} as const;

export type AlignValues = keyof typeof alignValues;

const VideoComponent = ({
  source,
  size,
  alignment,
}: {
  source: string;
  size: number;
  alignment: AlignValues;
}) => {
  const [videoSource, setVideoSource] = useState<string>(source);

  useEffect(() => {
    if (source) {
      getApi()
        .get(source)
        .then((res: AxiosResponse<ServiceResponse<{ url: string }>>) => {
          setVideoSource(res.data.data.url);
        })
        .catch(() => {
          setVideoSource(source);
        });
    }
  }, [source]);

  return (
    <video
      controls
      className={alignValues[alignment]}
      style={{
        width: `${size}%`,
      }}
      src={videoSource}
    >
      <track kind="captions" />
    </video>
  );
};

export const VideoConfig = {
  itemRender: (
    <div className="flex h-24 flex-col items-center justify-center gap-2 py-4">
      <PlayCircleIcon className="mx-auto size-8 text-gray-500" />
      <p className="text-center text-gray-500">{t`Video`}</p>
    </div>
  ),
  label: "Video",
  fields: {
    source: {
      type: "custom",
      label: t`Source Video`,
      render: AvailableFields.videoFile,
    },
    size: {
      type: "custom",
      label: t`Size`,
      render: AvailableFields.slider,
    },
    alignment: {
      type: "radio",
      Label: t`Alignment`,
      options: [
        { label: "Left", value: "left" },
        { label: "Center", value: "center" },
        { label: "Right", value: "right" },
      ],
    },
  },
  defaultProps: {
    size: 100,
    alignment: "center",
    source:
      "https://videos.pexels.com/video-files/20600550/20600550-hd_1920_1080_30fps.mp4",
  },
  render: VideoComponent,
};
