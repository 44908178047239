import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { t } from "ttag";

import { ChevronArrowLeft, ModuleDoc, Switch, Typography } from "@/components";
import { Spinner } from "@/components/animations/Spinner";
import { useModules } from "@/hooks/useQuery";
import { Button } from "@/ui";
import { EditModule } from "./components/EditModule";
import { ListUnits } from "./components/ListUnits";

export const ModuleUnits = () => {
  const { id } = useParams();
  const { useGetModule } = useModules();
  const [memberOnlyChecked, setMemberOnlyChecked] = useState(false);
  const [editModule, setEditModule] = useState(false);

  const getModuleQuery = useGetModule(Number(id) ?? "");

  useEffect(() => {
    if (!getModuleQuery.data) return;
    setMemberOnlyChecked(
      getModuleQuery.data.required_membership === "Premium" ? true : false,
    );
  }, [getModuleQuery.data?.required_membership]);

  if (getModuleQuery.isLoading || !getModuleQuery.data) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner />
      </div>
    );
  }
  return (
    <div className="flex flex-col px-16 py-11">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <Button
            variant="transparent"
            className="px-0"
            onClick={() => history.back()}
          >
            <ChevronArrowLeft />
          </Button>
          <Typography className="mx-4 text-2xl text-gray" font="semiBold">
            {getModuleQuery.data.name}
          </Typography>
        </div>
        {editModule && (
          <div className="flex flex-row items-center">
            <Switch
              checked={memberOnlyChecked}
              onCheckedChange={() => {
                setMemberOnlyChecked(!memberOnlyChecked);
              }}
            />
            <Typography className="ml-2 mr-4 text-base text-gray">
              {t`Content for members only`}
            </Typography>
          </div>
        )}
      </div>

      <div className="relative my-10 flex w-full grow flex-col self-start rounded-xl border border-light-base p-6">
        {!editModule ? (
          <div className="flex w-full flex-row items-start justify-between">
            <div className="flex flex-row overflow-hidden text-ellipsis">
              <ModuleDoc className="mr-4 min-w-32" />
              <div className="flex flex-col">
                <Typography className="text-2xl text-main-blue" font="semiBold">
                  {getModuleQuery.data.name}
                </Typography>
                <Typography className="mt-2 max-w-[80%] text-base text-main-blue">
                  {getModuleQuery.data.description ?? "-"}
                </Typography>
              </div>
            </div>
            <Button
              variant="tertiary"
              size="lg"
              onClick={() => setEditModule(true)}
            >{t`Edit`}</Button>
          </div>
        ) : (
          <EditModule
            module={getModuleQuery.data}
            onClose={setEditModule}
            memberOnlyChecked={memberOnlyChecked}
          />
        )}
      </div>

      <ListUnits
        units={getModuleQuery.data.units}
        moduleId={getModuleQuery.data.id}
      />
    </div>
  );
};
