import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { t } from "ttag";
import { z } from "zod";

import { Typography } from "@/components/Typography";
import { useInvitations } from "@/hooks/useQuery/useInvitations";
import { ROUTES } from "@/router";
import { Button, InputPassword } from "@/ui";
import { tw } from "@/utils";
import { AuthLogo } from "./AuthLogo";

const SetPasswordSchema = z
  .object({
    password: z
      .string()
      .min(8, {
        message: t`Password must be at least 8 characters long`,
      })
      .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[?!@#$&*]).{8,}$/, {
        message: t`Password must contain at least one number, one uppercase and one lowercase letter and one special character (?!@#$&)`,
      }),
    confirmPassword: z
      .string()
      .min(1, { message: t`Confirm password is required` }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

type FormValues = z.infer<typeof SetPasswordSchema>;

export const SetPassword = () => {
  const { useAcceptInvitations } = useInvitations();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") ?? "";
  const token = searchParams.get("token") ?? "";
  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    resolver: zodResolver(SetPasswordSchema),
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    useAcceptInvitations.mutate(
      {
        token,
        password: data.password,
        password_confirmation: data.confirmPassword,
      },
      {
        onSuccess: () => {
          navigate(ROUTES.setPassword.confirmation);
        },
      },
    );
  };

  return (
    <div className="flex flex-1 grow flex-row items-center justify-center">
      <AuthLogo />
      <div className="flex h-screen w-1/2 grow flex-col items-center justify-center bg-white">
        <form
          className="flex w-auto flex-col md:w-96"
          onSubmit={(e) => handleSubmit(onSubmit)(e)}
        >
          <Typography className="text-4xl text-main-blue" font="medium">
            {t`Set password`}
          </Typography>

          <Typography className="mt-2 text-base text-black">{email}</Typography>

          <InputPassword
            containerClassName="mt-8"
            className="bg-transparent"
            compact={!errors.password}
            id={"password"}
            placeholder="********************"
            label={t`New Password`}
            {...register("password")}
            error={errors.password?.message}
          />

          <InputPassword
            containerClassName="mt-5"
            className="bg-transparent"
            compact={!errors.confirmPassword}
            id={"confirmPassword"}
            placeholder="********************"
            label={t`Confirm password`}
            {...register("confirmPassword")}
            error={errors.confirmPassword?.message}
          />

          <Button
            disabled={!isDirty || useAcceptInvitations.isPending}
            type="submit"
            className={tw("mt-6 w-full text-lg font-semibold")}
            loading={useAcceptInvitations.isPending}
          >
            {t`Save changes`}
          </Button>
        </form>
      </div>
    </div>
  );
};
