import { useMutation } from "@tanstack/react-query";

import { forgotPassword, login, resetPassword } from "@/api";
import { useUserStore } from "@/stores";
import { errorToast } from "@/ui";

export const useAuth = () => {
  const setUser = useUserStore((state) => state.setUser);
  const setToken = useUserStore((state) => state.setToken);

  const useLogin = useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      setUser({ ...data.user, role: data.user.roles[0]?.name ?? "User" });
      setToken(data.token);
    },
  });

  const useForgotPassword = useMutation({
    mutationFn: forgotPassword,
    onError: (e) => {
      errorToast(e);
    },
  });

  const useResetPassword = useMutation({
    mutationFn: resetPassword,
  });

  return {
    useLogin,
    useForgotPassword,
    useResetPassword,
  };
};
