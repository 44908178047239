import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { createSlide, getSlides, updateSlide } from "@/api";
import { unitQueryKeys } from "./useUnits";

export const slideQueryKeys = {
  getSlidesQuery: () => ["getSlidesQuery"],
};

export const useSlides = () => {
  const queryClient = useQueryClient();

  const useGetSlides = () => {
    return useQuery({
      queryFn: getSlides,
      queryKey: slideQueryKeys.getSlidesQuery(),
      initialData: [],
    });
  };

  const useCreateSlide = useMutation({
    mutationFn: createSlide,
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: slideQueryKeys.getSlidesQuery(),
      });
      void queryClient.invalidateQueries({
        queryKey: unitQueryKeys.getUnitQuery(variables.unit),
      });
    },
  });

  const useUpdateSlide = useMutation({
    mutationFn: updateSlide,
    onSuccess: (response) => {
      void queryClient.invalidateQueries({
        queryKey: unitQueryKeys.getUnitQuery(response.unit.id),
      });
    },
  });

  return {
    useGetSlides,
    useUpdateSlide,
    useCreateSlide,
  };
};
