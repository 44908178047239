import type { UserRole } from ".";
import type { ServiceResponse } from "./api.types";
import { getApi } from "./axios";
import type { Membership } from "./invitations";

export interface User {
  id: number;
  name: string;
  email: string;
  role: UserRole;
  membership: Membership;
  created_at: string;
}

interface UpdateUserParams {
  id: number;
  role: UserRole;
  membership: Membership;
}

export const userTypeToUser: Record<
  string,
  {
    role: string;
    membership: string;
  }
> = {
  Editor: {
    role: "Editor",
    membership: "Free",
  },
  Free: {
    role: "User",
    membership: "Free",
  },
  Member: {
    role: "User",
    membership: "Premium",
  },
  Admin: {
    role: "Admin",
    membership: "Free",
  },
  "Super Admin": {
    role: "Super Admin",
    membership: "Free",
  },
} as const;

export const userToUserType = (role: string, membership: string) => {
  return Object.keys(userTypeToUser).find(
    (key) =>
      userTypeToUser[key]?.role === role &&
      userTypeToUser[key]?.membership === membership,
  );
};

export const getUsers = async () => {
  const { data } = await getApi().get<ServiceResponse<User[]>>("/users");
  return data.data;
};

export const getUser = async (id: number) => {
  const { data } = await getApi().get<ServiceResponse<User>>(`/users/${id}`);
  return data.data;
};

export const deleteUser = async (id: number) => {
  const data = await getApi().delete(`/users/${id}`);
  return data;
};

export const updateUser = async (params: UpdateUserParams) => {
  const data = await getApi().put(`/users/${params.id}`, {
    role: params.role,
    membership: params.membership,
  });
  return data;
};
