import type { ReactNode } from "react";
import type { Config } from "@measured/puck";
import { t } from "ttag";

import { AvailableComponents } from "./puckCustoms/customComponents";

const overrides = {
  componentItem: ({ name }: { name: string }) => {
    const availableComponent =
      AvailableComponents[name as keyof typeof AvailableComponents];

    return (
      <div className="my-2 w-full rounded border border-gray-400">
        {availableComponent?.itemRender}
      </div>
    );
  },
};

export const initialData = {
  content: [],
  root: {
    props: {
      title: "Root",
    },
  },
  zones: {},
};

export const puckConfig = {
  categories: {
    layout: {
      components: ["Spacing", "Column", "Row"],
      label: t`Layout`,
    },
    typography: {
      components: ["Heading", "Text"],
      label: t`Typography`,
    },
    multiMedia: {
      components: ["Image", "Video"],
      label: t`Media`,
    },
  },
  components: AvailableComponents,
  root: {
    render: ({ children }: { children: ReactNode }) => {
      return (
        <div
          style={{
            overflow: "scroll",
            height: "100%",
            width: "100%",
          }}
        >
          {children}
        </div>
      );
    },
  },
} as Config;

export const puckEditorProps = {
  config: puckConfig,
  data: initialData,
  overrides: overrides,
  iframe: {
    enabled: false,
  },
} as const;

export const getRootSize = () => {
  return {
    width: document.getElementById("puck-root")?.clientWidth ?? 1,
    height: document.getElementById("puck-root")?.clientHeight ?? 1,
  };
};
