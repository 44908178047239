import type { ComponentConfig } from "@measured/puck";
import { DropZone } from "@measured/puck";
import { t } from "ttag";

import { tw } from "@/utils";
import { Section } from "../../components/Section";
import { getRootSize } from "../../utils";
import { AvailableFields } from "../customFields";

export interface FlexProps {
  filled: keyof typeof filledValues;
  items: { minItemHeight?: number }[];
}

const filledValues = {
  transparent: "bg-transparent",
  white: "border border-gray-200/70 bg-white",
} as const;

export const RowsConfig: ComponentConfig<FlexProps> & {
  itemRender: JSX.Element;
} = {
  itemRender: (
    <div className="flex h-24 flex-col items-center justify-center gap-2 py-4">
      <div className="flex h-10 w-24 flex-col gap-2">
        <div className="h-10 rounded-sm border border-gray-100 bg-gray-50"></div>
        <div className="h-10 rounded-sm border border-gray-100 bg-gray-50"></div>
      </div>
      <p className="text-center text-gray-500">{t`Row`}</p>
    </div>
  ),
  fields: {
    filled: {
      type: "radio",
      label: t`Filled`,
      options: [
        {
          value: "transparent",
          label: t`Transparent`,
        },
        {
          value: "white",
          label: t`White`,
        },
      ],
    },
    items: {
      type: "array",
      label: t`Items`,
      defaultItemProps: {
        minItemHeight: 20,
      },
      arrayFields: {
        minItemHeight: {
          label: t`Minimum Item Height`,
          type: "custom",
          // @ts-expect-error - This is a custom field, library typing is wrong
          render: AvailableFields.slider,
        },
      },
      getItemSummary: (_, id) => `Item ${(id ?? 0) + 1}`,
    },
  },
  defaultProps: {
    filled: "transparent",
    items: [
      {
        minItemHeight: 20,
      },
      {
        minItemHeight: 20,
      },
    ],
  },
  render: ({ items, filled }) => {
    const rootHeight = getRootSize().height;
    return (
      <Section>
        <div className="flex size-full min-h-0 min-w-0 flex-col gap-6">
          {items.map((item, idx) => {
            const realMinHeight = item.minItemHeight
              ? (item.minItemHeight / rootHeight) * 3000
              : 0.1;
            return (
              <div
                key={idx}
                className={tw("flex-1 rounded-lg", filledValues[filled])}
              >
                <DropZone
                  zone={`item-${idx}`}
                  style={{
                    minHeight: realMinHeight,
                  }}
                />
              </div>
            );
          })}
        </div>
      </Section>
    );
  },
};
