import { useUserStore } from "@/stores";
import { AdminSidebar } from "./AdminSidebar";
import { UserSidebar } from "./UserSidebar";

export const Sidebar = () => {
  const isAdmin = useUserStore((state) =>
    ["Admin", "Super Admin"].includes(state.user?.role ?? ""),
  );

  return <>{isAdmin ? <AdminSidebar /> : <UserSidebar />}</>;
};
