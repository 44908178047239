import { t } from "ttag";

import { Spinner } from "@/components/animations/Spinner";
import { Typography } from "@/components/Typography";
import { useModules } from "@/hooks/useQuery";
import { ModuleItem } from "./components";

export const Modules = () => {
  const { useGetModules } = useModules();

  const getModulesQuery = useGetModules();

  if (getModulesQuery.isLoading || !getModulesQuery.data) {
    return (
      <div className="flex h-screen flex-1 items-center justify-center">
        <Spinner />
      </div>
    );
  }
  return (
    <div className="flex flex-col px-9 py-10">
      <Typography className="mb-6 text-3xl text-gray-700" font="semiBold">
        {t`Use Cases & Concepts`}
      </Typography>

      <div className="flex flex-col gap-4">
        {getModulesQuery.data.map((module) => (
          <ModuleItem key={module.id} module={module} />
        ))}
      </div>
    </div>
  );
};
