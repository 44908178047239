import type { IconProps } from "./IconProps";

export function ChevronArrowDown({ className, ...props }: IconProps) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 10L12.7071 15.2929C12.3166 15.6834 11.6834 15.6834 11.2929 15.2929L6 10"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
