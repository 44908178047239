import type { IconProps } from "./IconProps";

export function NoInvites({ className, ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={203}
      height={146}
      fill="none"
      className={className}
      {...props}
    >
      <path
        fill="#E6E7E8"
        d="m178.003 88.073-.333 1.817c-2.843 15.324-13.335 27.608-27.561 31.511a35.609 35.609 0 0 1-5.587 1.07c-13.641 1.52-28.182-4.82-37.27-16.259-5.345-6.727-9.17-15.279-16.367-19.586-9.376-5.612-20.705-1.943-30.468 1.816-9.772 3.768-21.11 7.402-30.467 1.745-5.858-3.534-9.637-10.153-11.616-16.934-5.264-17.94.8-37.725 12.759-50.675C43.05 9.628 60.103 2.91 77.386.761c24.205-3.004 50.245 2.806 70.257 18.814 20.011 16.007 32.959 42.797 30.359 68.498Z"
      />
      <path
        fill="#14BAC6"
        fillRule="evenodd"
        d="M37.192 64.979v63.278c0 7.521 6.092 13.613 13.613 13.613h95.282c7.521 0 13.613-6.092 13.613-13.613V64.979"
        clipRule="evenodd"
      />
      <path
        stroke="#14BAC6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        d="M37.192 64.979v63.278c0 7.521 6.092 13.613 13.613 13.613h95.282c7.521 0 13.613-6.092 13.613-13.613V64.979"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        stroke="#14BAC6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        d="m43.223 53.687 47.604-32.024a13.66 13.66 0 0 1 15.239 0l47.604 32.023c8.018 5.398 8.018 17.193 0 22.59l-39.981 26.899a27.292 27.292 0 0 1-30.478 0L43.23 76.277c-8.025-5.398-8.025-17.2-.007-22.59Z"
        clipRule="evenodd"
      />
      <path
        stroke="#14BAC6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        d="M75.424 57.057h46.051M80.489 75.325h36.11"
      />
      <path
        fill="#E1E4E5"
        d="M21.347 105.134 10.9 115.58a.974.974 0 1 0 1.378 1.377l10.445-10.445a.975.975 0 0 0-1.377-1.378ZM20.324 112.613l-3.013 3.012a.975.975 0 0 0 1.378 1.378l3.012-3.012a.975.975 0 0 0-1.377-1.378Z"
      />
      <mask id="a" fill="#fff">
        <path d="m182.056 46.73 9.76-9.761a.91.91 0 0 0-1.287-1.287l-9.76 9.76a.91.91 0 0 0 1.287 1.287Z" />
      </mask>
      <path
        fill="#E1E4E5"
        d="m182.056 46.73 9.76-9.761a.91.91 0 0 0-1.287-1.287l-9.76 9.76a.91.91 0 0 0 1.287 1.287Z"
      />
      <path
        fill="#0C1432"
        d="m186.159 31.312-9.76 9.76 11.314 11.314 9.76-9.76-11.314-11.314Zm-9.76 9.76a7.09 7.09 0 0 1 10.027 0l-11.314 11.314a8.91 8.91 0 0 0 12.601 0l-11.314-11.314Zm10.027 0-11.314 11.314 11.314-11.314Zm0 0a7.092 7.092 0 0 1 0 10.027l-11.314-11.314a8.91 8.91 0 0 0 0 12.6l11.314-11.313Zm0 10.027 9.76-9.76-11.314-11.314-9.76 9.76L186.426 51.1Zm9.76-9.76a7.09 7.09 0 0 1-10.027 0l11.314-11.314a8.91 8.91 0 0 0-12.601 0l11.314 11.313Zm-10.027 0 11.314-11.314-11.314 11.313Zm0 0a7.092 7.092 0 0 1 0-10.027l11.314 11.314a8.91 8.91 0 0 0 0-12.601l-11.314 11.314Z"
        mask="url(#a)"
      />
      <path
        fill="#E1E4E5"
        stroke="#0C1432"
        strokeWidth={8}
        d="M182.998 38.467a3.065 3.065 0 0 1-.547-.74l1.362-1.362c.266.14.515.323.739.547h.001c.224.224.406.474.547.74l-1.362 1.362a3.092 3.092 0 0 1-.74-.547Z"
      />
      <path
        stroke="#0C1432"
        strokeMiterlimit={10}
        strokeWidth={8}
        d="M11.765 95.127a3.883 3.883 0 1 1-3.878-3.883 3.876 3.876 0 0 1 3.878 3.883Z"
      />
      <path
        fill="#14BAC6"
        d="M201.337 65.053a6.423 6.423 0 1 1-12.409-3.325 6.423 6.423 0 1 1 12.409 3.325Z"
      />
      <path
        fill="#E1E4E5"
        d="M147.158 27.02c-.341 1.274-1.463 2.08-2.506 1.801-1.043-.279-1.612-1.539-1.27-2.814.341-1.274 1.463-2.081 2.506-1.802 1.043.28 1.612 1.54 1.27 2.815Z"
      />
      <path
        fill="#fff"
        d="m52.306 124.792-9.288 9.288a.866.866 0 1 0 1.225 1.225l9.288-9.289a.866.866 0 1 0-1.225-1.224ZM59.907 124.792l-9.288 9.288a.866.866 0 1 0 1.225 1.225l9.288-9.289a.866.866 0 1 0-1.225-1.224Z"
      />
    </svg>
  );
}
