import type { User } from "@/stores";
import { useUserStore } from "@/stores";
import { getApi } from "./axios";

export type UserRole = "Super Admin" | "Admin" | "Editor" | "User";

interface LoginParams {
  email: string;
  password: string;
}

interface LoginResponse {
  user: User;
  token: string;
}

interface ForgotPasswordResponse {
  message: string;
}

interface ResetPasswordParams {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
}

interface ResetPasswordResponse {
  message: string;
}

export const login = async (params: LoginParams) => {
  const { data } = await getApi({ isPrivateApi: false }).post<LoginResponse>(
    "/auth/login",
    params,
  );
  return data;
};

export const forgotPassword = async (email: string) => {
  const { data } = await getApi().post<ForgotPasswordResponse>(
    "/auth/password/request",
    {
      email,
    },
  );
  return data;
};

export const resetPassword = async (params: ResetPasswordParams) => {
  const { data } = await getApi().post<ResetPasswordResponse>(
    "/auth/password/reset",
    params,
  );
  return data;
};

export const logout = () => {
  const { clearUser } = useUserStore.getState();

  clearUser();
};
