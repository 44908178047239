import type { Unit } from "@/api";
import { Typography } from "@/components/Typography";
import { tw } from "@/utils";

interface UnitItemProps {
  unit: Unit;
  classes?: string;
}

export const UnitItem = ({ unit, classes }: UnitItemProps) => {
  return (
    <div className={tw("flex w-full border-b border-b-gray-100 py-6", classes)}>
      <Typography className="text-gray-500">
        {`${unit.name} - ${unit.description}`}
      </Typography>
    </div>
  );
};
