import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { t } from "ttag";
import { z } from "zod";

import { useSlides } from "@/hooks/useQuery";
import { initialData } from "@/screens/Admins/Slides/Editor/utils";
import type { ModalProps } from "@/shared.types";
import { Button, errorToast, Input, Modal } from "@/ui";

const slideSchema = z.object({
  name: z
    .string()
    .min(1, { message: "Name is required" })
    .max(255, { message: "Name is too long" }),
});

type SlideFormValues = z.infer<typeof slideSchema>;
export const CreateSlideModal = ({ show, onClose }: ModalProps) => {
  const { useCreateSlide } = useSlides();
  const { unitId } = useParams();
  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
  } = useForm<SlideFormValues>({
    resolver: zodResolver(slideSchema),
  });

  const onSubmit = (values: SlideFormValues) => {
    const slideParams = {
      name: values.name,
      unit: Number(unitId),
      data: initialData,
      files: [],
    };

    useCreateSlide.mutate(slideParams, {
      onError: (error) => {
        errorToast(error);
      },
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <Modal
      show={show}
      title="Create Slide"
      description="Fill in the required details below to create an empty slide."
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-7">
        <div className="flex flex-col">
          <Input
            id="name"
            label="Name"
            placeholder="Enter Name"
            {...register("name")}
            error={errors.name?.message}
          />
        </div>

        <div className="flex justify-end gap-2.5">
          <Button onClick={onClose} variant="outline" className="min-w-28">
            {t`Cancel`}
          </Button>

          <Button
            type="submit"
            disabled={!isDirty}
            loading={useCreateSlide.isPending}
            className="min-w-28"
          >
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};
