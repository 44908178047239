import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { Tabs } from "@/components";
import { Spinner } from "@/components/animations/Spinner";
import { Typography } from "@/components/Typography";
import { useModules } from "@/hooks/useQuery";
import { ROUTES } from "@/router";
import { Button } from "@/ui";
import { tw } from "@/utils";
import { ModulesTable } from "./components/ModulesTable";

export const ConceptsAndUseCases = () => {
  const { useGetModules } = useModules();
  const getModulesQuery = useGetModules();
  const navigate = useNavigate();

  const tabs = {
    Modules: <ModulesTable modules={getModulesQuery.data ?? []} />,
  } as const;

  type Tabs = keyof typeof tabs;

  const [activeTab, setActiveTab] = useState<Tabs>("Modules");

  if (getModulesQuery.isLoading || !getModulesQuery.data) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col px-9 py-10">
      <div className="flex flex-row items-center justify-between">
        <Typography className="text-3xl text-gray" font="semiBold">
          {t`Use Cases & Concepts`}
        </Typography>
        {getModulesQuery.data.length !== 0 && (
          <Button
            variant="tertiary"
            className="self-start"
            onClick={() => navigate(ROUTES.conceptsAndUseCases.createModule)}
          >
            {t`Create new module`}
          </Button>
        )}
      </div>

      <Tabs
        className="mt-6 gap-1 border-b-2"
        tabs={Object.keys(tabs) as Tabs[]}
        value={activeTab}
        onChange={(status) => setActiveTab(status)}
        renderTab={({ tab, onClick, selected }) => (
          <Button
            key={tab}
            variant="transparent"
            className={tw(
              "-mb-0.5 px-4 text-base font-normal text-gray-300",
              selected &&
                "rounded-none border-b-2 border-b-gray font-semibold text-gray",
            )}
            onClick={onClick}
          >
            <div className="flex flex-row">
              <Typography font="semiBold" className="text-main-blue">
                {tab}
              </Typography>
              {getModulesQuery.data?.length !== 0 && (
                <div className="-mt-1 ml-2 flex size-7 flex-row items-center justify-center rounded-full bg-main-blue">
                  <Typography font="semiBold" className="text-sm text-teal-100">
                    {getModulesQuery.data?.length}
                  </Typography>
                </div>
              )}
            </div>
          </Button>
        )}
      />
      <div className="flex flex-col items-center">{tabs[activeTab]}</div>
    </div>
  );
};
