import { InviteUpdated, Typography } from "@/components";
import { tw } from "@/utils";

export const SuccessfullyUpdated = ({
  className,
  title,
  description,
}: {
  className?: string;
  title: string;
  description?: string;
}) => {
  return (
    <div className={tw("flex flex-col items-center justify-center", className)}>
      <InviteUpdated />
      <Typography
        className="mb-4 mt-3 text-main-blue"
        variant="xl"
        font="medium"
      >
        {title}
      </Typography>
      <Typography
        className="text-center text-gray-700"
        variant="small"
        font="medium"
      >
        {description}
      </Typography>
    </div>
  );
};
