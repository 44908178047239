import type { IconProps } from "./IconProps";

export function Trash({ className, ...props }: IconProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      fill="none"
      {...props}
    >
      <path
        fill="#354863"
        d="m7.092 21.282.454-.891-.454.891Zm-.874-.874.891-.454-.891.454Zm11.564 0-.891-.454.891.454Zm-.874.874-.454-.891.454.891ZM5 6.5a1 1 0 1 0 0 2v-2Zm14 2a1 1 0 1 0 0-2v2Zm-2-1v10.8h2V7.5h-2Zm-2.2 13H9.2v2h5.6v-2ZM5 7.5v10.8h2V7.5H5Zm4.2 13c-.577 0-.949 0-1.232-.024-.272-.022-.373-.06-.422-.085l-.908 1.782c.378.193.772.264 1.167.296.384.032.851.031 1.395.031v-2ZM5 18.3c0 .544 0 1.011.03 1.395.033.395.104.789.297 1.167l1.782-.908c-.025-.05-.063-.15-.085-.422C7 19.25 7 18.877 7 18.3H5Zm2.546 2.091a1 1 0 0 1-.437-.437l-1.782.908a3 3 0 0 0 1.311 1.311l.908-1.782ZM17 18.3c0 .577 0 .949-.024 1.232-.022.272-.06.372-.085.422l1.782.908c.193-.378.264-.772.296-1.167.032-.384.031-.851.031-1.395h-2Zm-2.2 4.2c.544 0 1.011 0 1.395-.03.395-.033.789-.104 1.167-.297l-.908-1.782c-.05.025-.15.063-.422.085-.283.023-.655.024-1.232.024v2Zm2.091-2.546a1 1 0 0 1-.437.437l.908 1.782a3 3 0 0 0 1.311-1.311l-1.782-.908ZM5 8.5h1v-2H5v2Zm1 0h12v-2H6v2Zm12 0h1v-2h-1v2ZM9.5 6.7c0-1.133 1.033-2.2 2.5-2.2v-2c-2.4 0-4.5 1.798-4.5 4.2h2ZM12 4.5c1.467 0 2.5 1.067 2.5 2.2h2c0-2.402-2.101-4.2-4.5-4.2v2ZM7.5 6.7v.8h2v-.8h-2Zm7 0v.8h2v-.8h-2Z"
      />
    </svg>
  );
}
