import { useLocation, useNavigate } from "react-router-dom";
import { t } from "ttag";

import { HealthTechLogo } from "@/components/icons/HealthTechLogo";
import { Logout } from "@/components/icons/Logout";
import { Typography } from "@/components/Typography";
import { useModules } from "@/hooks/useQuery";
import { ROUTES } from "@/router";
import { useUserStore } from "@/stores";
import { Button } from "@/ui";
import { tw } from "@/utils";
import { ModuleNavItem } from "./components";

interface SidebarOption {
  label: string;
  route: string;
}

const sidebarOptions: SidebarOption[] = [
  {
    label: t`USE CASES & CONCEPTS`,
    route: ROUTES.conceptsAndUseCases.base,
  },
];

export const UserSidebar = () => {
  const logout = useUserStore((state) => state.clearUser);
  const user = useUserStore((state) => state.user);

  const location = useLocation();
  const navigate = useNavigate();
  const { useGetModules } = useModules();

  const getModulesQuery = useGetModules();

  return (
    <div className="mb-2 flex h-screen min-w-64 max-w-64 grow flex-col justify-between overflow-y-auto bg-main-blue">
      <div className="flex flex-col items-center">
        <HealthTechLogo className="mt-8" />
        <div className="mt-10 flex flex-col gap-1">
          {sidebarOptions.map((option) => (
            <div key={option.label} className="flex w-64 flex-row">
              <div
                className={tw(
                  "h-full w-1 bg-healthtech-blue",
                  location.pathname !== option.route && "bg-transparent",
                )}
              />
              <button
                className={tw(
                  "ease-in-ou flex w-full flex-row items-center gap-1 px-6 py-3 text-base font-semibold transition-all duration-200",
                  location.pathname === option.route && "bg-gray-500",
                )}
                onClick={() => navigate(option.route)}
              >
                <Typography
                  font="semiBold"
                  className="text-base text-dark-base-main hover:text-dark-base-main"
                >
                  {`${option.label}`}
                </Typography>
              </button>
            </div>
          ))}
          <div className="ml-7 mr-6 mt-3 flex flex-col border-b-2 border-gray-500 pb-10">
            {getModulesQuery.data?.map((module) => (
              <ModuleNavItem key={module.id} module={module} />
            ))}
          </div>
        </div>
      </div>
      <div className="mb-6 flex max-w-64 flex-row items-center justify-between pt-3">
        <div className="ml-7 flex max-w-[60%] flex-col justify-start">
          <Typography font="semiBold" className="text-base text-dark-base-main">
            {user?.name}
          </Typography>
          <Typography className="text-sm text-gray-300">
            {user?.email}
          </Typography>
        </div>
        <Button
          className="mr-5 mt-1 self-start p-0"
          onClick={() => {
            logout();
            navigate(ROUTES.login);
          }}
          variant="transparent"
        >
          <Logout />
        </Button>
      </div>
    </div>
  );
};
