import { useState } from "react";
import type { Field } from "@measured/puck";
import { FieldLabel } from "@measured/puck";

import { useFiles } from "@/hooks/useQuery/useFiles";

export const ImageFileField = ({
  field,
  onChange,
}: {
  field: Field;
  onChange: (value: string) => void;
}) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const { useUploadFile } = useFiles();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setUploading(true);
      useUploadFile.mutate(
        {
          file,
          type: "Image",
          onUploadProgress: (progress) => {
            setProgress(progress * 100);
          },
        },
        {
          onSuccess: (url) => {
            onChange(url);
            setUploading(false);
          },
          onError: () => {
            setUploading(false);
          },
        },
      );
    }
  };

  return (
    <FieldLabel label={field.label ?? ""}>
      <div className="flex flex-col gap-4">
        <div className="flex h-10 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100">
          <div className="flex flex-row items-center justify-between px-0.5 pb-6 pt-5">
            <svg
              className="size-6 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              ></path>
            </svg>
            <p className="text-sm text-gray-500">Click to upload File</p>
          </div>
          <input
            id="dropzone-file"
            type="file"
            className="hidden"
            disabled={uploading}
            onChange={handleFileChange}
            accept="image/*"
          />
        </div>
      </div>
      {uploading && (
        <div className="mt-2 flex flex-col gap-1">
          <p className="text-xs text-gray-500">Uploading...</p>
          <div className="h-1 w-full rounded bg-gray-400">
            <div
              className="h-1 w-full bg-healthtech-blue"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      )}
    </FieldLabel>
  );
};
