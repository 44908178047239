import AuthImageJpg from "@/assets/AuthImage.jpg";
import AuthImageJpgWebp from "@/assets/AuthImage.webp";

export const AuthLogo = () => {
  return (
    <div className="flex h-screen w-3/5 items-center justify-center bg-slate-700">
      <picture className="size-full overflow-hidden">
        <source srcSet={AuthImageJpgWebp} type="image/webp" />
        <source srcSet={AuthImageJpg} type="image/jpeg" />
        <img src={AuthImageJpg} alt="" className="size-full" />
      </picture>
    </div>
  );
};
