import type { ReactNode } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useUserStore } from "@/stores/useUserStore";
import { ROUTES } from "./routes";

const HOME = {
  loggedOut: ROUTES.base,
  Admin: ROUTES.conceptsAndUseCases.base,
  User: ROUTES.modules.base,
  "Super Admin": ROUTES.conceptsAndUseCases.base,
  Editor: ROUTES.conceptsAndUseCases.base,
} as const;

type UserState = "loggedOut" | "Admin" | "User" | "Super Admin" | "Editor";

export const ProtectedRoute = ({
  children,
  expected,
}: {
  children?: ReactNode;
  expected: UserState[];
}) => {
  const userState = useUserStore((state) => state.user?.role ?? "loggedOut");

  if (!expected.includes(userState)) {
    return <Navigate to={HOME[userState]} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};
