import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { t } from "ttag";

import type { Module } from "@/api";
import { ChevronArrowDown, Chip, ModuleIcon } from "@/components";
import { Typography } from "@/components/Typography";
import { ROUTES } from "@/router";
import { Button } from "@/ui";
import { tw } from "@/utils";
import { UnitItem } from "./UnitItem";

export const ModuleItem = ({ module }: { module: Module }) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleExpandClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (module.units.length === 0) return;
    setExpanded(!expanded);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      const path = generatePath(ROUTES.modules.moduleUnits, {
        id: module.id.toString(),
      });
      navigate(path);
    }
  };

  return (
    <div
      className="relative flex cursor-pointer flex-col rounded-xl border border-light-base px-8 py-6"
      onClick={() => {
        const path = generatePath(ROUTES.modules.moduleUnits, {
          id: module.id.toString(),
        });
        navigate(path);
      }}
      onKeyDown={handleKeyPress}
      role="button"
      tabIndex={0}
    >
      <div
        className={tw("flex w-full flex-1 flex-row overflow-hidden rounded-xl")}
      >
        <ModuleIcon />
        <div className="ml-6 flex w-2/3 flex-1 grow flex-row justify-between">
          <div className="flex w-11/12 flex-col justify-start">
            <div className="flex flex-row items-center">
              <Typography
                className={tw(
                  "flex items-center break-all text-left text-xl text-gray-700",
                  !expanded &&
                    module.name.length > 50 &&
                    "overflow-hidden text-ellipsis",
                  expanded && "max-h-full overflow-auto text-clip",
                )}
                style={{
                  display: expanded ? "flex" : "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                }}
              >
                {module.name}
              </Typography>
              {module.required_membership === "Premium" && (
                <Chip
                  className="ml-2 mt-1 min-w-32 self-start bg-green-200 text-xs"
                  size="sm"
                >
                  {t`For Members Only`}
                </Chip>
              )}
            </div>
            <Typography
              className={tw(
                "mt-2 max-w-[80%] text-left text-base text-light-base-second",
                !expanded &&
                  module.description.length > 150 &&
                  "max-h-12 overflow-hidden text-ellipsis",
                expanded && "max-h-full overflow-auto text-clip",
              )}
              font="semiBold"
            >
              {module.description}
            </Typography>
          </div>

          {module.units.length > 0 && (
            <Button
              variant="transparent"
              className="self-start p-0"
              onClick={handleExpandClick}
            >
              <ChevronArrowDown
                className={tw(
                  "size-8 min-w-8 stroke-gray-25",
                  expanded && "rotate-180",
                )}
              />
            </Button>
          )}
        </div>
      </div>
      {expanded && (
        <div className="mt-3 flex w-full flex-1 flex-col">
          {module.units.map((unit, index) => (
            <UnitItem
              key={unit.id}
              unit={unit}
              classes={tw(
                "",
                module.units.length - 1 === index && "border-b-0 pb-0",
              )}
            />
          ))}
        </div>
      )}
    </div>
  );
};
