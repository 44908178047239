import { useState } from "react";
import { t } from "ttag";

import { StarsModule, Typography } from "@/components";
import { useUnits } from "@/hooks/useQuery";
import { Button, Modal } from "@/ui";

export const DeleteUnitModal = ({
  onClose,
  deleteUnit,
  moduleId,
}: {
  onClose: () => void;
  deleteUnit: {
    open: boolean;
    selectedUnit: number;
  };
  moduleId: number;
}) => {
  const [deleted, setDeleted] = useState(false);
  const { useDeleteUnit } = useUnits();

  return (
    <Modal
      show={deleteUnit.open}
      title={deleted ? "" : t`Edit invite`}
      onClose={onClose}
    >
      {deleted ? (
        <div className="flex flex-col items-center">
          <StarsModule />
          <Typography className="mt-3 text-2xl font-medium text-main-blue">
            {t`Unit successfully deleted`}
          </Typography>
        </div>
      ) : (
        <div className="flex flex-col">
          <Typography className="mb-6 text-base font-medium text-gray">
            {t`Are you sure you want to remove this Unit?`}
          </Typography>
          <div className="flex flex-row">
            <Button
              variant="secondary"
              className="mr-1 w-1/2"
              disabled={useDeleteUnit.isPending}
              onClick={onClose}
            >
              {t`Cancel`}
            </Button>
            <Button
              disabled={useDeleteUnit.isPending}
              variant="primary"
              className="ml-1 w-1/2"
              onClick={() => {
                useDeleteUnit.mutate(
                  {
                    unitId: deleteUnit.selectedUnit,
                    moduleId: moduleId,
                  },
                  {
                    onSuccess: () => {
                      setDeleted(true);
                    },
                  },
                );
              }}
            >
              {t`Remove unit`}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
