import { ColumnsConfig } from "./Column";
import { HeadingConfig } from "./Heading";
import { ImageConfig } from "./Image";
import { RowsConfig } from "./Rows";
import { SpacingConfig } from "./Spacing";
import { TextConfig } from "./Text";
import { VideoConfig } from "./Video";

export const AvailableComponents = {
  Text: TextConfig,
  Image: ImageConfig,
  Row: RowsConfig,
  Column: ColumnsConfig,
  Heading: HeadingConfig,
  Spacing: SpacingConfig,
  Video: VideoConfig,
};
