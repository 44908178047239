import type { Data } from "@measured/puck";

import type { ServiceResponse } from "./api.types";
import { getApi } from "./axios";
import type { Unit } from "./units";

export interface Slide {
  id: number;
  name: string;
  data: Data;
  unit: Unit;
}

interface CreateSlideParams {
  name: string;
  unit: number;
  data: Data;
  files: string[];
}

interface UpdateSlideParams {
  name?: string;
  data?: Data;
  files?: string[];
}

export const getSlides = async () => {
  const { data } = await getApi().get<ServiceResponse<Slide[]>>("/slides");
  return data.data;
};

export const createSlide = async (params: CreateSlideParams) => {
  const data = await getApi().post<ServiceResponse<Slide>>("/slides", params);
  return data;
};

export const updateSlide = async ({
  slideId,
  params,
}: {
  slideId: number;
  params: UpdateSlideParams;
}): Promise<Slide> => {
  const data = await getApi().put<ServiceResponse<Slide>>(
    `/slides/${slideId}`,
    params,
  );
  return data.data.data;
};
