import type { ComponentPropsWithoutRef, ForwardedRef } from "react";

import { Spinner } from "@/components/animations/Spinner";
import { forwardRef, tw } from "@/utils";

const BUTTON_VARIANT = {
  primary:
    "bg-healthtech-blue text-white hover:bg-teal-600 focus:bg-healthtech-blue focus:ring-teal-400 text-base font-semibold",
  secondary:
    "font-semibold text-main-blue border border-main-blue font-semibold text-base",
  tertiary:
    "bg-main-blue font-semibold text-base text-white hover:bg-gray-500 focus:bg-main-blue focus:ring-gray-100 ",
  outline:
    "border-gray-300 text-gray-300 hover:border-gray-400 hover:text-gray-800",
  transparent: "bg-transparent",
} as const;

export type ButtonVariant = keyof typeof BUTTON_VARIANT;

const BUTTON_VARIANT_DISABLED = {
  primary: "bg-gray-50 text-gray-200",
  secondary: "bg-transparent text-light-base",
  tertiary: "text-gray-200 bg-gray-50",
  outline: "border-gray-300 text-gray-300",
  transparent: "bg-transparent text-light-base-second",
} as const;

export type ButtonVariantDisabled = keyof typeof BUTTON_VARIANT_DISABLED;

const SIZE = {
  sm: "px-3 py-2",
  md: "px-4 py-2.5",
  lg: "px-5 py-3",
  xl: "px-6 py-4",
} as const;
type Size = keyof typeof SIZE;

export interface ButtonProps extends ComponentPropsWithoutRef<"button"> {
  variant?: ButtonVariant;
  size?: Size;
  loading?: boolean;
}

export const Button = forwardRef(
  (
    {
      type = "button",
      loading = false,
      className,
      variant = "primary",
      size = "md",
      disabled = false,
      children,
      ...props
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <button
      ref={ref}
      type={type}
      className={tw(
        "flex items-center justify-center gap-2 overflow-hidden text-ellipsis rounded-lg border border-transparent text-base font-semibold focus:outline-none focus:ring focus:ring-transparent",

        !disabled && [BUTTON_VARIANT[variant]],

        disabled && [BUTTON_VARIANT_DISABLED[variant]],

        size && [SIZE[size]],

        className,
      )}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? <Spinner className={tw("size-5")} /> : children}
    </button>
  ),
);
