import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { t } from "ttag";
import { z } from "zod";

import { useInvitations } from "@/hooks/useQuery/useInvitations";
import { Button, Input, Modal } from "@/ui";
import { SuccessfullyUpdated } from "./components";

const inviteUserSchema = z.object({
  email: z
    .string()
    .toLowerCase()
    .min(1, { message: t`Email is required` })
    .email({ message: t`Invalid email` }),
});
type InviteUserFormValues = z.infer<typeof inviteUserSchema>;

export const EditInviteModal = ({
  onClose,
  editInvite,
}: {
  onClose: () => void;
  editInvite: {
    open: boolean;
    selectedInviteId: number;
    selectedInviteEmail: string;
  };
}) => {
  const [updated, setUpdate] = useState(false);
  const { useUpdateInvitation } = useInvitations();

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
  } = useForm<InviteUserFormValues>({
    resolver: zodResolver(inviteUserSchema),
    defaultValues: {
      email: editInvite.selectedInviteEmail,
    },
  });

  return (
    <Modal
      show={editInvite.open}
      title={updated ? "" : t`Edit invite`}
      onClose={onClose}
    >
      {updated ? (
        <SuccessfullyUpdated
          title={t`Invite successfully edited`}
          description={t`A new link was send to the new email. The previous link is already expired.`}
        />
      ) : (
        <form
          onSubmit={(e) => {
            void handleSubmit((value) =>
              useUpdateInvitation.mutate(
                {
                  id: editInvite.selectedInviteId,
                  email: value.email,
                },
                {
                  onSuccess: () => {
                    setUpdate(true);
                  },
                },
              ),
            )(e);
          }}
          className="flex flex-col gap-7"
        >
          <div className="flex flex-col bg-white">
            <Input
              type="email"
              id="email"
              label="Email"
              placeholder="adam_smith@gmail.com"
              {...register("email")}
              error={errors.email?.message}
            />
          </div>

          <div className="flex gap-2.5">
            <Button
              onClick={onClose}
              variant="secondary"
              className="w-full"
              disabled={useUpdateInvitation.isPending}
            >
              {t`Cancel`}
            </Button>

            <Button
              type="submit"
              disabled={!isDirty || useUpdateInvitation.isPending}
              className="w-full"
            >
              {t`Send invite again`}
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};
