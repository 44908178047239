import type { ComponentPropsWithoutRef, ForwardedRef, ReactNode } from "react";

import { forwardRef, tw } from "@/utils";
import { Label } from "./Label";
import { Message } from "./Message";
import type { FormErrorType } from "./Message";

const SIZE = {
  sm: "max-h-32",
  md: "max-h-60",
  lg: "max-h-96",
} as const;
type Size = keyof typeof SIZE;

interface TextAreaProps extends ComponentPropsWithoutRef<"textarea"> {
  compact?: boolean;
  containerClassName?: string;
  secondaryContainerClassName?: string;
  tooltipClassName?: string;
  error?: FormErrorType;
  label?: ReactNode;
  size?: Size;
  message?: string;
  readOnlyPlaceholder?: string;
  requiredMarker?: boolean;
}

export const TextArea = forwardRef(
  (
    {
      className,
      compact,
      containerClassName,
      secondaryContainerClassName,
      error,
      id,
      label,
      message,
      placeholder,
      readOnly,
      size = "md",
      readOnlyPlaceholder = "-",
      style,
      requiredMarker,
      ...props
    }: TextAreaProps,
    ref: ForwardedRef<HTMLTextAreaElement>,
  ) => {
    return (
      <div
        style={style}
        className={tw("flex flex-col gap-1.5", containerClassName)}
      >
        {!!label && (
          <Label
            htmlFor={id}
            label={label}
            className={tw(
              "text-base font-medium text-gray-700",
              readOnly && "text-neutrals-dark-300",
            )}
            requiredMarker={requiredMarker}
          />
        )}

        <div
          className={tw(
            "relative flex flex-row items-center rounded-md",
            secondaryContainerClassName,
          )}
        >
          <textarea
            ref={ref}
            readOnly={readOnly}
            placeholder={readOnly ? readOnlyPlaceholder : placeholder}
            {...props}
            onChange={(e) => {
              if (props.onChange) props.onChange(e);
            }}
            className={tw(
              "lock w-full rounded-md border border-light-base bg-base-second p-3 text-base text-gray placeholder:text-gray-300 focus:outline-none focus:ring-1 focus:ring-gray-500",
              !!error &&
                "border-danger-700 focus:border-danger-700 focus:ring-red-50",

              !!props.disabled &&
                !readOnly &&
                "border-neutrals-medium-300 cursor-not-allowed bg-white",
              size && [SIZE[size]],

              readOnly &&
                "text-neutrals-dark-500 resize-none border-transparent px-0 font-medium shadow-none focus:border-transparent focus:ring-0",

              className,
            )}
          />
        </div>

        {(!compact || !!message || !!error) && (
          <Message message={message} error={error} />
        )}
      </div>
    );
  },
);
