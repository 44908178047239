import { t } from "ttag";

import { Button, Modal } from "@/ui";

export const ExitSlideModal = ({
  show,
  onClose,
  onDiscard,
  onSubmit,
}: {
  show: boolean;
  onClose: () => void;
  onDiscard: () => void;
  onSubmit: () => void;
}) => {
  return (
    <Modal
      show={show}
      title={"Save last changes before leaving"}
      description="You have unsaved changes. Do you want to save them before leaving?"
      onClose={onClose}
    >
      <div className="flex justify-end gap-2.5">
        <Button onClick={onDiscard} variant="outline" className="min-w-28">
          {t`Discard`}
        </Button>
        <Button className="min-w-28" onClick={onSubmit}>
          {t`Save`}
        </Button>
      </div>
    </Modal>
  );
};
