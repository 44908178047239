import { tw } from "@/utils";
import type { IconProps } from "./IconProps";

export function EyeOpenedIcon({ className }: IconProps) {
  return (
    <svg
      className={tw("stroke-inherit", className)}
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={16}
      fill="none"
    >
      <path
        strokeWidth={2}
        d="M1.91 9.172a2.808 2.808 0 0 1 0-2.344A10.002 10.002 0 0 1 11 1c4.034 0 7.51 2.388 9.091 5.828.342.743.342 1.6 0 2.344A10.001 10.001 0 0 1 11.001 15c-4.034 0-7.51-2.388-9.091-5.828Z"
      />
      <path strokeWidth={2} d="M14 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
    </svg>
  );
}
