import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { t } from "ttag";
import { z } from "zod";

import type { Module } from "@/api";
import { Typography } from "@/components/Typography";
import { useModules } from "@/hooks/useQuery";
import { Button, Input } from "@/ui";
import { TextArea } from "@/ui/form/TextArea";

export const moduleSchema = z.object({
  name: z.string().min(1, { message: "Name is required" }).max(256, {
    message: "Name must be less than 256 characters",
  }),
  description: z
    .string()
    .min(1, { message: "Description is required" })
    .max(1000, { message: "Description is too long" }),
});

export type EditModuleFormValues = z.infer<typeof moduleSchema>;

interface EditModuleProps {
  module: Module;
  onClose: (value: boolean) => void;
  memberOnlyChecked: boolean;
}

export const EditModule = ({
  module,
  onClose,
  memberOnlyChecked = false,
}: EditModuleProps) => {
  const { useUpdateModule } = useModules();
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<EditModuleFormValues>({
    resolver: zodResolver(moduleSchema),
    defaultValues: {
      name: module.name,
      description: module.description,
    },
  });

  const onSubmit: SubmitHandler<EditModuleFormValues> = (data) => {
    useUpdateModule.mutate(
      {
        id: module.id,
        name: data.name,
        description: data.description,
        required_membership: memberOnlyChecked ? "Premium" : "Free",
      },
      {
        onSuccess: () => onClose(false),
      },
    );
  };

  return (
    <form
      className="flex flex-col p-1"
      onSubmit={(e) => handleSubmit(onSubmit)(e)}
    >
      <div className="flex items-center justify-between">
        <Typography className="text-2xl text-main-blue" font="medium">
          {t`Module`}
        </Typography>

        <div className="flex">
          <Button variant="secondary" onClick={() => onClose(false)}>
            {t`Cancel`}
          </Button>
          <Button variant="tertiary" className="ml-2" type="submit">
            {t`Save changes`}
          </Button>
        </div>
      </div>
      <div className="mt-2 flex w-full grow flex-col self-start">
        <Input
          id="module-name"
          className="bg-transparent"
          compact={!errors.description}
          placeholder="Write the Module name"
          label={t`Module name`}
          {...register("name")}
          error={errors.name?.message}
          requiredMarker
        />

        <TextArea
          autoComplete="off"
          containerClassName="mt-4"
          compact={!errors.description}
          size="sm"
          id="description"
          placeholder={t`Write here the Module description`}
          className="resize-none bg-transparent"
          {...register("description")}
          error={errors?.description?.message}
          label={t`Description`}
          rows={12}
          maxLength={1000}
          requiredMarker
        />
      </div>
    </form>
  );
};
