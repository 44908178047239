import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";
import { z } from "zod";

import { Typography } from "@/components/Typography";
import { useAuth } from "@/hooks/useQuery";
import { ROUTES } from "@/router";
import { Button, Input } from "@/ui";
import { tw } from "@/utils";
import { AuthLogo } from "./AuthLogo";

const ForgotPasswordSchema = z.object({
  email: z
    .string()
    .toLowerCase()
    .min(1, { message: t`Email is required` })
    .email({ message: t`Invalid email` }),
});

type FormValues = z.infer<typeof ForgotPasswordSchema>;

export const ForgotPassword = () => {
  const { useForgotPassword } = useAuth();
  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    resolver: zodResolver(ForgotPasswordSchema),
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    useForgotPassword.mutate(data.email, {
      onSuccess: () => {
        navigate(ROUTES.forgotPassword.confirmation);
      },
    });
  };

  return (
    <div className="flex flex-1 grow flex-row items-center justify-center">
      <AuthLogo />
      <div className="flex h-screen w-1/2 grow flex-col items-center justify-center bg-white">
        <form
          className="w-auto md:w-96"
          onSubmit={(e) => handleSubmit(onSubmit)(e)}
        >
          <Typography className="text-4xl text-main-blue" font="medium">
            {t`Forgot password?`}
          </Typography>
          <div className="mb-6 mt-5">
            <Typography className="text-base text-gray">
              {t`Please enter your registered email address. We will send you a link to reset your password`}
            </Typography>
          </div>
          <div className={tw("mt-6", !errors.email && "mb-3")}>
            <Input
              id={""}
              className="border-gray-300 bg-transparent"
              compact={!errors.email}
              placeholder="email@example.com"
              label={t`Email address`}
              {...register("email")}
              error={errors.email?.message}
            />
          </div>
          <div className="flex flex-row items-center justify-center">
            <Button
              disabled={useForgotPassword.isPending}
              variant="secondary"
              className={tw("border")}
              onClick={() => navigate(ROUTES.login)}
            >
              {t`Go back`}
            </Button>
            <Button
              disabled={!isDirty || useForgotPassword.isPending}
              type="submit"
              className={tw("my-4 ml-2 flex flex-1 text-lg font-semibold")}
              loading={useForgotPassword.isPending}
            >
              {t`Send email`}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
