import { forwardRef } from "react";
import { flexRender } from "@tanstack/react-table";
import type { Table as ReactTable } from "@tanstack/table-core";

import { tw } from "@/utils";

export const Table = forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
  <div className="flex rounded-xl border">
    <table
      ref={ref}
      className={tw("w-full caption-bottom text-sm", className)}
      {...props}
    />
  </div>
));
Table.displayName = "Table";

export const TableHeader = forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead ref={ref} className={className} {...props} />
));
TableHeader.displayName = "TableHeader";

export const TableBody = forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={tw("text-base text-gray [&_tr:last-child]:border-0", className)}
    {...props}
  />
));
TableBody.displayName = "TableBody";

export const TableFooter = forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot ref={ref} className={tw("font-medium", className)} {...props} />
));
TableFooter.displayName = "TableFooter";

export const TableRow = forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <tr ref={ref} className={tw("border-b", className)} {...props} />
));
TableRow.displayName = "TableRow";

export const TableHead = forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={tw(
      "sticky top-0 px-6 py-4 text-left align-middle text-sm font-semibold text-gray-500",
      className,
    )}
    {...props}
  />
));
TableHead.displayName = "TableHead";

export const TableCell = forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={tw("px-6 text-base text-gray", className)}
    {...props}
  />
));
TableCell.displayName = "TableCell";

export const TableCaption = forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={tw("mt-4 text-sm text-blue-500", className)}
    {...props}
  />
));
TableCaption.displayName = "TableCaption";

export function TableComponent<T>({
  tableData,
  onClickRow,
}: {
  tableData: ReactTable<T>;
  onClickRow?: (row: T) => void;
}) {
  return (
    <Table>
      <TableHeader>
        {tableData.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id} className="">
            {headerGroup.headers.map((header, id) => (
              <TableHead
                key={header.id}
                scope="col"
                className={tw(
                  "bg-gray-50",
                  id === 0 && "rounded-tl-xl",
                  id === headerGroup.headers.length - 1 && "rounded-tr-xl",
                )}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
              </TableHead>
            ))}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {tableData.getRowModel().rows.map((row, rowIndex) => (
          <TableRow
            key={row.id}
            className="cursor-pointer rounded-xl hover:bg-gray-50"
          >
            {row.getVisibleCells().map((cell, cellIndex) => (
              <TableCell
                key={cell.id}
                className={tw(
                  "max-w-48 overflow-hidden text-ellipsis",
                  rowIndex === tableData.getRowModel().rows.length - 1 &&
                    cellIndex === 0 &&
                    "rounded-bl-xl",
                  rowIndex === tableData.getRowModel().rows.length - 1 &&
                    cellIndex === row.getVisibleCells().length - 1 &&
                    "rounded-br-xl",
                  cell.column.id === "actions" && "w-32",
                )}
                onClick={() => {
                  if (cell.column.id !== "actions" && onClickRow) {
                    onClickRow(cell.row.original);
                  } else {
                    return;
                  }
                }}
              >
                <div className="my-4 max-h-12 overflow-hidden text-ellipsis">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
