import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { t } from "ttag";
import { z } from "zod";

import type { UserRole } from "@/api";
import type { Membership } from "@/api/invitations";
import { userToUserType, userTypeToUser } from "@/api/users";
import type { User } from "@/api/users";
import { useUsers } from "@/hooks/useQuery/useUsers";
import { Button, Modal, Select } from "@/ui";
import { UserTypeOptions } from "@/utils/constants";
import { SuccessfullyUpdated } from "./components";

const editUserSchema = z.object({
  userType: z.string().min(1, { message: t`User type is required` }),
});
type EditUserFormValues = z.infer<typeof editUserSchema>;

export const EditUserModal = ({
  onClose,
  editUser,
}: {
  onClose: () => void;
  editUser: {
    open: boolean;
    user: User;
  };
}) => {
  const [updated, setUpdate] = useState(false);
  const { useUpdateUser } = useUsers();

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm<EditUserFormValues>({
    resolver: zodResolver(editUserSchema),
    defaultValues: {
      userType: userToUserType(editUser.user.role, editUser.user.membership),
    },
  });

  const onSubmit: SubmitHandler<EditUserFormValues> = (data) => {
    useUpdateUser.mutate(
      {
        id: editUser.user.id,
        role: userTypeToUser[data.userType]?.role as UserRole,
        membership: userTypeToUser[data.userType]?.membership as Membership,
      },
      {
        onSuccess: () => {
          setUpdate(true);
          reset();
        },
      },
    );
  };

  return (
    <Modal
      show={editUser.open}
      title={updated ? "" : `${t`Edit`} ${editUser.user.name}`}
      onClose={onClose}
    >
      {updated ? (
        <SuccessfullyUpdated title={t`User successfully edited`} />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-7">
          <div className="flex flex-col bg-white">
            <Select
              label="User type"
              options={UserTypeOptions}
              id="user-type"
              disabled={useUpdateUser.isPending}
              containerClassName="w-full flex-1"
              className="h-11"
              {...register("userType")}
              error={errors.userType?.message}
            />
          </div>

          <div className="flex gap-2.5">
            <Button
              onClick={onClose}
              variant="secondary"
              className="w-full"
              disabled={useUpdateUser.isPending}
            >
              {t`Cancel`}
            </Button>

            <Button
              type="submit"
              disabled={!isDirty || useUpdateUser.isPending}
              className="w-full"
            >
              {t`Save changes`}
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};
