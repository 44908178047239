import { tw } from "@/utils";
import type { IconProps } from "./IconProps";

export function NoModules({ className, ...props }: IconProps) {
  return (
    <svg
      className={tw(className)}
      xmlns="http://www.w3.org/2000/svg"
      width={206}
      height={159}
      fill="none"
      {...props}
    >
      <path
        fill="#EDF2F9"
        d="M20.45 31.686C40.613 6.252 83.463-4.39 112.11 33.738c24.298 32.34 34.884 34.19 53.352 40.342 12.195 4.065 32.149 9.572 20.521 41.027-15.219 41.171-90.507 52.291-131.673 36.924C14.944 137.337-16.147 77.84 20.45 31.686Z"
      />
      <path
        fill="#EDF2F9"
        d="M120.531 25.728c9.241 16.026 26.744 36.666 55.885 45.039 29.141 8.368 34.33-8.77 25.546-28.694-8.783-19.924-30.338-37.465-57.083-41.45-26.744-3.986-31.934 11.952-24.348 25.105Z"
      />
      <path
        fill="#14BAC6"
        fillRule="evenodd"
        d="M149.837 48.407h6.975c7.707 0 13.95 6.25 13.95 13.95v63.194c0 7.708-6.243 13.95-13.95 13.95H59.161c-7.708 0-13.95-6.242-13.95-13.95v-83.7c0-7.708 6.242-13.95 13.95-13.95h31.185a6.972 6.972 0 0 1 5.762 3.047l9.8 14.404a6.968 6.968 0 0 0 5.768 3.055h10.261"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M142.961 43.445h-14.149c-3.905 0-7.075 3.125-7.075 6.975v34.875l14.15-6.975 14.149 6.975V50.42c0-3.85-3.17-6.975-7.075-6.975Z"
        clipRule="evenodd"
      />
      <path
        fill="#14BAC6"
        d="m26.853 31.722-5.02-5.021a3.986 3.986 0 0 0-5.637 0l-5.021 5.02a3.986 3.986 0 0 0 0 5.637l5.02 5.021a3.986 3.986 0 0 0 5.637 0l5.021-5.02a3.986 3.986 0 0 0 0-5.637ZM196.087 69.532l-.472-.471a3.986 3.986 0 0 0-5.637 0l-.472.471a3.987 3.987 0 0 0 0 5.637l.472.472a3.986 3.986 0 0 0 5.637 0l.472-.472a3.987 3.987 0 0 0 0-5.636Z"
      />
      <path
        fill="#fff"
        d="M99.417 62.178H58.364a1.993 1.993 0 1 0 0 3.986h41.053a1.993 1.993 0 1 0 0-3.986ZM85.666 70.548H58.563a2.192 2.192 0 1 0 0 4.384h27.103a2.192 2.192 0 1 0 0-4.384ZM85.666 79.316H58.563a2.192 2.192 0 1 0 0 4.385h27.103a2.192 2.192 0 1 0 0-4.385Z"
      />
      <path
        fill="#31446C"
        d="m64.159 117.794-8.522 8.521a.795.795 0 0 0 1.123 1.124l8.522-8.522a.794.794 0 1 0-1.123-1.123ZM71.13 117.794l-8.52 8.521a.795.795 0 0 0 1.123 1.124l8.522-8.522a.794.794 0 1 0-1.124-1.123ZM78.54 117.794l-8.522 8.521a.795.795 0 0 0 1.124 1.124l8.522-8.522a.795.795 0 1 0-1.124-1.123Z"
      />
      <path
        fill="#E1E4E5"
        d="m9.247 93.824-8.522 8.522a.794.794 0 1 0 1.124 1.123l8.521-8.521a.794.794 0 1 0-1.123-1.124ZM123.428 15.815l-8.522 8.522a.795.795 0 0 0 1.123 1.123l8.522-8.521a.795.795 0 0 0-1.123-1.124ZM193.157 89.03l-8.522 8.522a.795.795 0 0 0 1.123 1.124l8.522-8.522a.795.795 0 0 0-1.123-1.124ZM182.262 15.815l-8.522 8.522a.795.795 0 0 0 1.123 1.123l8.522-8.521a.795.795 0 0 0-1.123-1.124ZM8.412 99.926l-2.457 2.457a.795.795 0 0 0 1.123 1.124l2.458-2.458a.794.794 0 1 0-1.124-1.123ZM122.593 21.916l-2.457 2.458a.795.795 0 0 0 1.123 1.124l2.458-2.458a.795.795 0 0 0-1.124-1.123ZM192.322 95.132l-2.457 2.457a.795.795 0 0 0 1.123 1.124l2.458-2.458a.795.795 0 0 0-1.124-1.123ZM181.427 21.916l-2.457 2.458a.793.793 0 1 0 1.123 1.124l2.458-2.458a.795.795 0 0 0-1.124-1.123Z"
      />
      <path
        fill="url(#a)"
        d="M148.296 131.592c3.345.004 8.158-.867 12.101-4.667 3.942-3.801 1.726-6.162-2.188-6.59-3.914-.428-8.607 1.356-11.647 5.179-3.039 3.822-1.011 6.075 1.734 6.078Z"
      />
      <path
        fill="#354863"
        d="M108.946 158.53a3.242 3.242 0 1 0 0-6.484 3.242 3.242 0 0 0 0 6.484ZM17.102 125.033c1.79 0 3.242-1.209 3.242-2.701s-1.452-2.701-3.242-2.701-3.241 1.209-3.241 2.701 1.45 2.701 3.241 2.701Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1={165.974}
          x2={135.547}
          y1={132.842}
          y2={115.663}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#EEE" />
        </linearGradient>
      </defs>
    </svg>
  );
}
