import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { t } from "ttag";
import { z } from "zod";

import type { UserRole } from "@/api";
import { userTypeToUser } from "@/api";
import type { Membership } from "@/api/invitations";
import { useInvitations } from "@/hooks/useQuery/useInvitations";
import { Button, Input, Modal, Select } from "@/ui";
import { UserTypeOptions } from "@/utils/constants";

const inviteUserSchema = z.object({
  name: z.string().min(1, { message: "Name is required" }),
  email: z
    .string()
    .toLowerCase()
    .min(1, { message: t`Email is required` })
    .email({ message: t`Invalid email` }),
  userType: z.string().min(1, { message: t`User type is required` }),
});
type InviteUserFormValues = z.infer<typeof inviteUserSchema>;

export const InviteUserModal = ({
  onClose,
  show,
}: {
  onClose: () => void;
  show: boolean;
}) => {
  const { useCreateInvitation } = useInvitations();

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm<InviteUserFormValues>({
    resolver: zodResolver(inviteUserSchema),
  });

  const onSubmit: SubmitHandler<InviteUserFormValues> = (data) => {
    useCreateInvitation.mutate(
      {
        email: data.email,
        name: data.name,
        role: userTypeToUser[data.userType]?.role as UserRole,
        membership: userTypeToUser[data.userType]?.membership as Membership,
      },
      {
        onSuccess: () => {
          reset();
          onClose();
        },
        onError: () => {
          setError("email", {
            type: "manual",
            message:
              "User already exists or invitation already sent in the last 5 minutes",
          });
        },
      },
    );
  };

  useEffect(() => {
    if (!show) {
      reset();
    }
  }, [show, reset]);

  return (
    <Modal show={show} title="Invite user" onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-7">
        <div className="flex flex-col bg-white">
          <Input
            id="name"
            label="User name"
            placeholder="Adam Smith"
            {...register("name")}
            disabled={useCreateInvitation.isPending}
            error={errors.name?.message}
          />

          <Input
            type="email"
            id="email"
            label="Email"
            placeholder="adam_smith@gmail.com"
            disabled={useCreateInvitation.isPending}
            {...register("email")}
            error={errors.email?.message}
          />

          <Select
            label="User type"
            options={UserTypeOptions}
            id="states"
            disabled={useCreateInvitation.isPending}
            containerClassName="w-full flex-1"
            className="h-11"
            {...register("userType")}
            error={errors.userType?.message}
          />
        </div>

        <div className="flex justify-end gap-2.5">
          <Button onClick={onClose} variant="secondary" className="min-w-28">
            {t`Cancel`}
          </Button>

          <Button
            type="submit"
            disabled={!isDirty || useCreateInvitation.isPending}
            className="min-w-28"
          >
            {t`Send invite`}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
