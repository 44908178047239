import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  createModule,
  deleteModule,
  getModule,
  getModules,
  updateModule,
} from "@/api";
import { errorToast } from "@/ui";

export const moduleQueryKeys = {
  getModulesQuery: () => ["getModulesQuery"],
  getModuleQuery: (id: number) => ["getModuleQuery", id],
};

export const useModules = () => {
  const queryClient = useQueryClient();

  const useGetModules = () =>
    useQuery({
      queryKey: moduleQueryKeys.getModulesQuery(),
      queryFn: getModules,
    });

  const useGetModule = (moduleId: number) =>
    useQuery({
      queryKey: moduleQueryKeys.getModuleQuery(moduleId),
      queryFn: () => getModule(moduleId),
    });

  const useDeleteModule = useMutation({
    mutationFn: deleteModule,
    onError: (e) => {
      errorToast(e);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: moduleQueryKeys.getModulesQuery(),
      });
    },
  });

  const useCreateModule = useMutation({
    mutationFn: createModule,
    onError: (e) => {
      errorToast(e);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: moduleQueryKeys.getModulesQuery(),
      });
    },
  });

  const useUpdateModule = useMutation({
    mutationFn: updateModule,
    onError: (e) => {
      errorToast(e);
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: moduleQueryKeys.getModulesQuery(),
      });
      void queryClient.invalidateQueries({
        queryKey: moduleQueryKeys.getModuleQuery(variables.id),
      });
    },
  });

  return {
    useGetModules,
    useDeleteModule,
    useCreateModule,
    useGetModule,
    useUpdateModule,
  };
};
