import { useMemo, useState } from "react";
import type { ColumnDef } from "@tanstack/react-table";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { t } from "ttag";

import { userToUserType } from "@/api";
import type { Invitation } from "@/api/invitations";
import { Trash, Typography } from "@/components";
import { TableComponent } from "@/components/Table";
import { useInvitations } from "@/hooks/useQuery/useInvitations";
import { EditInviteModal } from "@/modals/EditInviteModal";
import { Button, Modal } from "@/ui";
import { EmptyInvites } from "./EmptyInvites";

export const InvitesTable = () => {
  const { useGetInvitations, useDeleteInvitation } = useInvitations();

  const getInvitationsQuery = useGetInvitations();
  const [deleteInvite, setDeleteInvite] = useState({
    open: false,
    selectedInvite: 0,
  });

  const [editInvite, setUpdateInvite] = useState({
    open: false,
    selectedInviteId: 0,
    selectedInviteEmail: "",
  });

  const columns = useMemo<ColumnDef<Invitation>[]>(
    () => [
      {
        header: "User",
        accessorFn: (row: Invitation) => row.name,
      },
      {
        header: "Email",
        accessorFn: (row: Invitation) => row.email,
      },
      {
        header: "Invite Type",
        accessorFn: (row: Invitation) =>
          userToUserType(row.role, row.membership),
      },
      {
        id: "actions",
        cell: ({ row }) => (
          <div className="flex flex-row items-center justify-end">
            <Button
              variant="transparent"
              onClick={() => {
                setDeleteInvite({
                  open: true,
                  selectedInvite: row.original.id,
                });
              }}
            >
              <Trash />
            </Button>
          </div>
        ),
      },
    ],
    [],
  );

  const table = useReactTable({
    data: getInvitationsQuery?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const closeModal = () => setDeleteInvite({ open: false, selectedInvite: 0 });

  if (getInvitationsQuery.data?.length === 0) {
    return <EmptyInvites className="mt-32" />;
  }

  return (
    <div className="flex w-full grow flex-col self-start py-6">
      <TableComponent tableData={table} />
      {editInvite.selectedInviteEmail && (
        <EditInviteModal
          editInvite={editInvite}
          onClose={() =>
            setUpdateInvite({
              open: false,
              selectedInviteId: 0,
              selectedInviteEmail: "",
            })
          }
        />
      )}
      <Modal
        show={deleteInvite.open}
        title={t`Remove invite`}
        onClose={closeModal}
      >
        <div className="flex flex-col">
          <Typography className="mb-6 text-base font-medium text-gray">
            {t`Are you sure you want to remove the invitation?`}
            <br />
            {t`The link the user received will be expired.`}
          </Typography>
          <div className="flex flex-row">
            <Button
              variant="secondary"
              className="mr-1 w-1/2"
              disabled={useDeleteInvitation.isPending}
              onClick={closeModal}
            >
              {t`Cancel`}
            </Button>
            <Button
              disabled={useDeleteInvitation.isPending}
              variant="primary"
              className="ml-1 w-1/2"
              onClick={() => {
                useDeleteInvitation.mutate(deleteInvite.selectedInvite, {
                  onSuccess: closeModal,
                });
              }}
            >
              {t`Remove invite`}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
