import { useParams } from "react-router";
import { generatePath, useNavigate } from "react-router-dom";
import { t } from "ttag";

import { ChevronArrowLeft, Chip, ModuleIcon, Typography } from "@/components";
import { Spinner } from "@/components/animations/Spinner";
import { useModules } from "@/hooks/useQuery";
import { ROUTES } from "@/router";
import { Button } from "@/ui";
import { UnitsList } from "./components";

export const ModuleView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { useGetModule } = useModules();

  const getModuleQuery = useGetModule(Number(id) ?? "");

  if (getModuleQuery.isLoading || !getModuleQuery.data) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner />
      </div>
    );
  }

  const navigateToSlides = (unitId: number) => {
    const path = generatePath(ROUTES.modules.unitSlides, {
      id: id ?? "",
      unitId: unitId.toString(),
    });

    navigate(path);
  };

  return (
    <div className="flex flex-col px-16 py-11">
      <div className="flex flex-row items-center justify-between">
        <div className="flex w-full flex-col self-start">
          <div className="flex w-full max-w-[80%] flex-row items-center self-start">
            <Button
              variant="transparent"
              className="self-start p-0 pt-2"
              onClick={() => navigate(ROUTES.modules.base)}
            >
              <ChevronArrowLeft />
            </Button>
            <div className="relative flex w-full flex-col">
              <div className="flex flex-row">
                <Typography
                  className="mx-4 flex max-w-[90%] break-all text-3xl text-main-blue"
                  font="semiBold"
                >
                  {getModuleQuery.data.name}
                </Typography>
                {getModuleQuery.data.required_membership === "Premium" && (
                  <Chip
                    className="ml-2 mt-2 min-w-32 self-start bg-green-200 text-xs"
                    size="sm"
                  >
                    {t`For Members Only`}
                  </Chip>
                )}
              </div>
            </div>
          </div>

          <Typography className="mt-3 w-full overflow-hidden text-ellipsis text-lg text-main-blue">
            {getModuleQuery.data.description}
          </Typography>
        </div>

        <div className="self-start">
          <ModuleIcon className="size-32 self-start" />
        </div>
      </div>

      <UnitsList
        units={getModuleQuery.data.units}
        onSelect={navigateToSlides}
      />
    </div>
  );
};
