/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useState } from "react";
import type { ComponentPropsWithoutRef, ForwardedRef, ReactNode } from "react";

import { EyeClosedIcon, EyeOpenedIcon } from "@/components/icons";
import { forwardRef, tw } from "@/utils";
import { IconWrapper } from "../common";
import { Label } from "./Label";
import { Message } from "./Message";

export interface InputPasswordProps extends ComponentPropsWithoutRef<"input"> {
  compact?: boolean;
  containerClassName?: string;
  error?: string | boolean;
  iconClassName?: string;
  id: string;
  label?: ReactNode;
  left?: ReactNode;
  message?: string;
}

export const InputPassword = forwardRef(
  (
    {
      className,
      compact,
      containerClassName,
      error,
      id,
      label,
      left,
      message,
      ...rest
    }: InputPasswordProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
      <div className={tw("relative isolate w-full", containerClassName)}>
        <div className="relative">
          {!!label && (
            <Label
              htmlFor={id}
              label={label}
              className="mb-1 text-base font-medium text-gray-700"
            />
          )}
          <div
            className={tw(
              "relative flex flex-row items-center rounded-md shadow-sm",
              !!rest.disabled && "opacity-30",
            )}
          >
            {!!left && (
              <div className="pointer-events-none absolute pl-3">
                <IconWrapper size="sm" className="text-gray-400">
                  {left}
                </IconWrapper>
              </div>
            )}
            <input
              ref={ref}
              type={showPassword ? "text" : "password"}
              id={id}
              {...rest}
              className={tw(
                "block w-full rounded-md border border-gray-300 bg-gray-800 px-3 py-3 text-sm text-gray placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-500",
                !!error &&
                  "border-danger-700 focus:border-danger-700 focus:ring-red-50",
                !!left && "pl-10",
                !!rest.disabled && "border-gray-500 bg-gray-100",
                className,
              )}
            />
            <div
              onClick={() =>
                setShowPassword((prevShowPassword) => !prevShowPassword)
              }
            >
              {showPassword ? (
                <EyeOpenedIcon
                  className={tw(
                    "absolute bottom-3.5 right-3 cursor-pointer",
                    error ? " stroke-danger-700" : "stroke-light-base-second",
                  )}
                />
              ) : (
                <EyeClosedIcon
                  className={tw(
                    "absolute bottom-3.5 right-3 cursor-pointer",
                    error
                      ? " fill-danger-700 stroke-danger-700"
                      : " fill-light-base-second stroke-light-base-second",
                  )}
                />
              )}
            </div>
          </div>
          {(!compact || !!message || !!error) && (
            <Message message={message} error={error} />
          )}
        </div>
      </div>
    );
  },
);
