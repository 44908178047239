import { useState } from "react";
import { t } from "ttag";

import { NoInvites } from "@/components/icons";
import { Typography } from "@/components/Typography";
import { InviteUserModal } from "@/modals";
import { Button } from "@/ui";
import { tw } from "@/utils";

export const EmptyInvites = ({ className }: { className?: string }) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className={tw("flex flex-col items-center", className)}>
      <NoInvites />
      <Typography className="my-4 text-base text-gray-700" font="semiBold">
        {t`No users invites yet`}
      </Typography>
      <Button
        className="w-full"
        variant="tertiary"
        onClick={() => setOpenModal(true)}
      >
        {t`Create new invite`}
      </Button>
      <InviteUserModal onClose={() => setOpenModal(false)} show={openModal} />
    </div>
  );
};
