import type { ServiceResponse } from "./api.types";
import { getApi } from "./axios";
import type { Slide } from "./slides";

export interface Unit {
  id: number;
  name: string;
  description: string;
  slides: Slide[];
  available: boolean;
  requiredUnit: number;
  completed: boolean;
}

interface CreateUnitParams {
  name: string;
  module: number;
  required_unit?: number;
}

interface UpdateUnitParams {
  id: number;
  name: string;
  module: number;
  required_unit?: number;
}

interface CompleteUnitParams {
  userId: number;
  unitId: number;
}

interface DeleteUnitParams {
  moduleId: number;
  unitId: number;
}

export const getUnits = async () => {
  const { data } = await getApi().get<ServiceResponse<Unit[]>>("/units");
  return data.data;
};

export const getUnit = async (id: number) => {
  const { data } = await getApi().get<ServiceResponse<Unit>>(`/units/${id}`);
  return data.data;
};

export const createUnit = async (params: CreateUnitParams) => {
  const data = await getApi().post("/units", params);
  return data;
};

export const updateUnit = async (params: UpdateUnitParams) => {
  const data = await getApi().put(`/units/${params.id}`, params);
  return data;
};

export const deleteUnit = async ({ unitId }: DeleteUnitParams) => {
  const data = await getApi().delete(`/units/${unitId}`);
  return data;
};

export const completeUnit = async (params: CompleteUnitParams) => {
  const data = await getApi().post(
    `/users/${params.userId}/units/${params.unitId}`,
  );
  return data;
};
