import type { IconProps } from "./IconProps";

export function Pencil({ className, ...props }: IconProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      fill="none"
      {...props}
    >
      <path d="M7.5 22.5a1 1 0 1 0 2 0h-2Zm1.211-8.422-.894-.447.894.447Zm3.342-6.684.894.448-.894-.448Zm.894 0 .895-.447-.895.447Zm3.342 6.684-.895.447.895-.447ZM15.5 22.5a1 1 0 1 0 2 0h-2Zm-6 0v-7.528h-2V22.5h2Zm.106-7.975 3.341-6.683-1.789-.895-3.341 6.683 1.789.895Zm2.447-6.683 3.341 6.683 1.79-.895-3.342-6.683-1.79.895Zm3.447 7.13V22.5h2v-7.528h-2Zm-.106-.447a1 1 0 0 1 .106.447h2a3 3 0 0 0-.317-1.341l-1.789.894Zm-2.447-6.683a.5.5 0 0 1-.894 0l1.789-.895c-.553-1.105-2.13-1.105-2.684 0l1.79.895ZM9.5 14.972a1 1 0 0 1 .106-.447l-1.79-.895a3 3 0 0 0-.316 1.342h2Zm3 .528c-1.468 0-2.682-.427-3.376-.981l-1.248 1.563c1.13.903 2.82 1.418 4.624 1.418v-2Zm3.376-.981c-.694.554-1.908.981-3.376.981v2c1.803 0 3.493-.515 4.624-1.418l-1.248-1.563ZM22.5 12.5c0 5.523-4.477 10-10 10v2c6.627 0 12-5.373 12-12h-2Zm-10 10c-5.523 0-10-4.477-10-10h-2c0 6.627 5.373 12 12 12v-2Zm-10-10c0-5.523 4.477-10 10-10v-2C5.873.5.5 5.873.5 12.5h2Zm10-10c5.523 0 10 4.477 10 10h2c0-6.627-5.373-12-12-12v2Z" />
    </svg>
  );
}
