import type { IconProps } from "./IconProps";

export function PlusSymbol({ className, ...props }: IconProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={17}
      fill="none"
      {...props}
    >
      <path
        stroke="#505357"
        strokeLinecap="round"
        strokeWidth={2}
        d="M9 1.167v14.666M16.334 8.5H1.667"
      />
    </svg>
  );
}
