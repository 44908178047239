import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import IndexBackground from "@/assets/IndexBackground.png";
import { HealthTechLogo } from "@/components";
import { ROUTES } from "@/router";
import { Button } from "@/ui";

export const IndexView = () => {
  const navigate = useNavigate();

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-8 bg-slate-700 text-white">
      <img
        src={IndexBackground}
        alt="Background"
        className="fixed inset-y-0 left-0 z-0 w-full"
      />
      <HealthTechLogo className="fixed left-8 top-8 z-10" />
      <h1 className="z-10 text-center text-5xl font-medium leading-normal">
        {t`Welcome to`} <br />
        Healthtech Build Studio
      </h1>
      <Button
        variant="primary"
        className="z-10 w-32 ring-0 focus:ring-0"
        onClick={() => navigate(ROUTES.login)}
      >
        {t`Login`}
      </Button>
    </div>
  );
};
