import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import type { Module } from "@/api";
import { ChevronArrowDown, Lock, Typography } from "@/components";
import { ROUTES } from "@/router";
import { Button } from "@/ui";
import { tw } from "@/utils";

export const ModuleNavItem = ({ module }: { module: Module }) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      if (module.units.length == 0) return;
      setExpanded(!expanded);
    }
  };

  const handleLinkClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const path = generatePath(ROUTES.modules.moduleUnits, {
      id: module.id.toString(),
    });
    navigate(path);
  };

  return (
    <div
      className={tw(
        "mb-3 flex flex-col",
        module.units.length === 0 && "cursor-default",
        expanded && "mb-2",
      )}
      onClick={() => {
        if (module.units.length == 0) return;
        setExpanded(!expanded);
      }}
      onKeyDown={handleKeyPress}
      role="button"
      tabIndex={0}
    >
      <div
        className="flex w-full flex-row items-center justify-between"
        key={module.id}
      >
        <Typography
          className="overflow-hidden text-ellipsis break-all text-left text-base text-gray-100"
          font="semiBold"
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
          }}
        >
          {module.name}
        </Typography>

        {module.units.length > 0 && (
          <ChevronArrowDown
            className={tw(
              "size-8 min-w-8 stroke-gray-25",
              expanded && "rotate-180",
            )}
          />
        )}
      </div>

      {expanded && (
        <div className="mt-3 flex w-full flex-col">
          {module.units.map((unit) => (
            <Button
              variant="transparent"
              key={unit.id}
              className="mb-3 flex flex-row items-center justify-between p-0"
              onClick={handleLinkClick}
            >
              <Typography
                className="ml-2 max-w-[80%] break-all text-left text-white"
                font="regular"
                variant="small"
                style={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                }}
              >
                {unit.name}
              </Typography>
              {!unit.available && (
                <Lock className="mr-1 mt-1 self-start stroke-white" />
              )}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};
