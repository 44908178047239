import { t } from "ttag";

import { getRootSize } from "../../utils";
import { AvailableFields } from "../customFields";
import { transformSliderValue } from "../customFields/Slider";

const TextComponent = ({
  content,
  fontSize,
}: {
  content: string;
  fontSize: number;
}) => {
  const rootWidth = getRootSize().width;

  fontSize = transformSliderValue(fontSize, 10, 60);

  const fontSizePx = `${(fontSize / 1000) * rootWidth}px`;

  return <p style={{ fontSize: fontSizePx }}>{content}</p>;
};

export const TextConfig = {
  itemRender: (
    <p className="py-1 text-center text-3xl text-gray-500">{t`Text`}</p>
  ),
  label: "Text",
  fields: {
    content: {
      type: "text",
      label: t`Content`,
    },
    fontSize: {
      type: "custom",
      label: t`Font Size`,
      render: AvailableFields.slider,
    },
  },
  defaultProps: {
    content: t`This is a text.`,
    fontSize: 20,
  },
  render: TextComponent,
};
