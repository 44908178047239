import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { StarsModule } from "@/components/icons";
import { Typography } from "@/components/Typography";
import { ROUTES } from "@/router";
import { Button } from "@/ui";
import { AuthLogo } from "./AuthLogo";

export const SetPasswordConfirmation = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-1 grow flex-row items-center justify-center">
      <AuthLogo />
      <div className="flex h-screen w-1/2 grow flex-col items-center justify-center bg-white">
        <div className="flex w-auto flex-col items-center md:w-96">
          <StarsModule />
          <Typography
            className="my-6 text-center text-2xl text-main-blue"
            font="medium"
          >
            {t`Account successfully created!`}
          </Typography>
          <Button
            className="mx-3 flex w-10/12 text-xl font-semibold"
            variant="tertiary"
            onClick={() => navigate(ROUTES.login)}
          >
            {t`Go back to Login`}
          </Button>
        </div>
      </div>
    </div>
  );
};
