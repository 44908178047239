import { useState } from "react";
import { t } from "ttag";

import type { Unit } from "@/api/units";
import { PlusSymbol, Typography } from "@/components";
import { DeleteUnitModal } from "@/modals";
import { Button } from "@/ui";
import { CreateUnit } from "./CreateUnit";
import { UnitCard } from "./UnitCard";

interface ListUnitsProps {
  moduleId: number;
  units: Unit[];
}

export const ListUnits = ({ moduleId, units }: ListUnitsProps) => {
  const [createUnit, setCreateUnit] = useState(false);
  const [deleteUnit, setDeleteUnit] = useState({
    open: false,
    selectedUnit: 0,
  });

  if (units.length === 0) {
    return (
      <CreateUnit moduleId={moduleId} onClose={() => setCreateUnit(false)} />
    );
  }

  return (
    <div className="flex flex-col">
      <Typography className="mb-4 text-2xl text-gray" font="semiBold">
        {t`Units`}
      </Typography>
      {units.map((unit, key) => (
        <UnitCard
          key={unit.id}
          unitItem={unit}
          moduleId={moduleId}
          className={key + 1 !== units.length ? "mb-3" : ""}
          units={units.length}
          onDeleteUnit={() =>
            setDeleteUnit({ open: true, selectedUnit: unit.id })
          }
        />
      ))}

      {!createUnit && (
        <Button
          className="mt-3 font-normal text-gray-500"
          variant="secondary"
          onClick={() => setCreateUnit(true)}
        >
          <PlusSymbol />
          {t`Add unit`}
        </Button>
      )}
      {createUnit && (
        <CreateUnit
          className="mt-5"
          moduleId={moduleId}
          onClose={() => setCreateUnit(false)}
        />
      )}
      <DeleteUnitModal
        deleteUnit={deleteUnit}
        onClose={() => setDeleteUnit({ open: false, selectedUnit: 0 })}
        moduleId={moduleId}
      />
    </div>
  );
};
