import { useState } from "react";
import { msgid, ngettext, t } from "ttag";

import { Chip, Tabs } from "@/components";
import { Spinner } from "@/components/animations/Spinner";
import { Typography } from "@/components/Typography";
import { useInvitations } from "@/hooks/useQuery/useInvitations";
import { useUsers } from "@/hooks/useQuery/useUsers";
import { InviteUserModal } from "@/modals";
import { Button } from "@/ui";
import { tw } from "@/utils";
import { InvitesTable } from "./components/InvitesTable";
import { EmptyInvites } from "./components/InvitesTable/EmptyInvites";
import { UsersTable } from "./components/UsersTable";

export const UsersManagement = () => {
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false);

  const tabs = ["Users", "Invites"] as const;
  type Tabs = (typeof tabs)[number];

  const { useGetInvitations } = useInvitations();
  const { useGetUsers } = useUsers();

  const [activeTab, setActiveTab] = useState<Tabs>(tabs[0]);

  const getInvitationsQuery = useGetInvitations();
  const { data: users } = useGetUsers();

  const renderTabContent = () => {
    switch (activeTab) {
      case "Invites":
        return <InvitesTable />;

      case "Users":
        return <UsersTable />;
    }
  };

  if (!users || !getInvitationsQuery.data) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (getInvitationsQuery.data?.length === 0 && users?.length === 0) {
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        <EmptyInvites />
      </div>
    );
  }

  return (
    <div className="flex flex-col px-9 py-10">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <Typography className="text-3xl text-gray" font="semiBold">
            {t`Users Management`}
          </Typography>
          <Chip className="ml-3 bg-teal-300 text-teal-600" size="sm">
            {activeTab === "Invites" ? (
              <>
                {`${ngettext(
                  msgid`${Number(getInvitationsQuery.data.length)} invite`,
                  `${Number(getInvitationsQuery.data.length)} invites`,
                  Number(getInvitationsQuery.data.length),
                )}`}
              </>
            ) : (
              <>
                {`${ngettext(
                  msgid`${Number(users.length)} user`,
                  `${Number(users.length)} users`,
                  Number(users.length),
                )}`}
              </>
            )}
          </Chip>
        </div>

        <Button variant="primary" onClick={() => setInviteUserModalOpen(true)}>
          {t`Invite user`}
        </Button>
      </div>

      <Tabs
        className="mt-6 gap-1 border-b-2"
        tabs={tabs}
        value={activeTab}
        onChange={(status) => setActiveTab(status)}
        renderTab={({ tab, onClick, selected }) => (
          <Button
            key={tab}
            variant="transparent"
            className={tw(
              "-mb-0.5 px-4 text-base font-normal",
              selected && "rounded-none border-b-2 border-b-gray",
            )}
            onClick={onClick}
          >
            <Typography
              font="semiBold"
              className={selected ? "text-main-blue" : "text-gray-300"}
            >
              {tab}
            </Typography>
          </Button>
        )}
      />
      <div className="flex flex-col items-center">{renderTabContent()}</div>
      <InviteUserModal
        show={inviteUserModalOpen}
        onClose={() => setInviteUserModalOpen(false)}
      />
    </div>
  );
};
