import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  completeUnit,
  createUnit,
  deleteUnit,
  getUnit,
  getUnits,
  updateUnit,
} from "@/api";
import { errorToast } from "@/ui";
import { moduleQueryKeys } from "./useModules";

export const unitQueryKeys = {
  getUnitsQuery: () => ["getUnitsQuery"],
  getUnitQuery: (id: number) => ["getUnitQuery", id],
};

export const useUnits = () => {
  const queryClient = useQueryClient();

  const useGetUnits = () =>
    useQuery({
      queryKey: unitQueryKeys.getUnitsQuery(),
      queryFn: getUnits,
    });

  const useGetUnit = (id: number) =>
    useQuery({
      queryKey: unitQueryKeys.getUnitQuery(id),
      queryFn: () => getUnit(id),
    });

  const useCreateUnit = useMutation({
    mutationFn: createUnit,
    onError: (e) => {
      errorToast(e);
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: unitQueryKeys.getUnitsQuery(),
      });
      void queryClient.invalidateQueries({
        queryKey: moduleQueryKeys.getModuleQuery(variables.module),
      });
    },
  });

  const useUpdateUnit = useMutation({
    mutationFn: updateUnit,
    onError: (e) => {
      errorToast(e);
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: unitQueryKeys.getUnitsQuery(),
      });
      void queryClient.invalidateQueries({
        queryKey: moduleQueryKeys.getModuleQuery(variables.module),
      });
      void queryClient.invalidateQueries({
        queryKey: unitQueryKeys.getUnitQuery(variables.id),
      });
    },
  });

  const useCompleteUnit = useMutation({
    mutationFn: completeUnit,
    onError: (e) => {
      errorToast(e);
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: unitQueryKeys.getUnitsQuery(),
      });
      void queryClient.invalidateQueries({
        queryKey: unitQueryKeys.getUnitQuery(variables.unitId),
      });
    },
  });

  const useDeleteUnit = useMutation({
    mutationFn: deleteUnit,
    onError: (e) => {
      errorToast(e);
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: unitQueryKeys.getUnitsQuery(),
      });
      void queryClient.invalidateQueries({
        queryKey: unitQueryKeys.getUnitQuery(variables.unitId),
      });
      void queryClient.invalidateQueries({
        queryKey: moduleQueryKeys.getModuleQuery(variables.moduleId),
      });
    },
  });

  return {
    useUpdateUnit,
    useGetUnits,
    useGetUnit,
    useDeleteUnit,
    useCreateUnit,
    useCompleteUnit,
  };
};
