import type { AxiosError, AxiosHeaders } from "axios";

import type { ServiceResponse } from "./api.types";
import { getApi } from "./axios";

export interface FileInterface {
  id: number;
  name: string;
  path: string;
  type: string;
  hash: string;
  extension: string;
}

interface ExistingFileResponse {
  error: {
    code: string;
    message: string;
    existing_file: FileInterface;
  };
}

export interface GetUploadUrlParams {
  file_hash: string;
  name: string;
  type: string;
}

interface PresignedUrlResponse {
  path: string;
  presigned_url: string;
}

interface StoreFileParams {
  file_hash: string;
  name: string;
  type: string;
  path: string;
}

export const getPresignedUrl = async (
  params: GetUploadUrlParams,
): Promise<PresignedUrlResponse | FileInterface> => {
  const data = await getApi()
    .get<ServiceResponse<PresignedUrlResponse>>("/files/upload-url", {
      params: {
        file_hash: params.file_hash,
        name: params.name,
        type: params.type,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((error: AxiosError<ExistingFileResponse>) => {
      if (error.response?.status === 409) {
        return error.response.data.error.existing_file;
      }
      throw error;
    });

  return data;
};

export const storeFile = async (params: StoreFileParams) => {
  const { data } = await getApi().post<ServiceResponse<FileInterface>>(
    "/files",
    params,
  );
  return data.data;
};

export const uploadFileToUrl = async ({
  url,
  file,
  onUploadProgress,
}: {
  url: string;
  file: File;
  onUploadProgress?: (progress: number) => void;
}): Promise<void> => {
  console.log("uploading file to url", url);
  await getApi().put(url, file, {
    onUploadProgress: (progressEvent) => {
      if (onUploadProgress && progressEvent.progress) {
        onUploadProgress(progressEvent.progress);
      }
    },
    transformRequest: (data: string, headers: AxiosHeaders) => {
      delete headers.Authorization;
      return data;
    },
  });
  return;
};

const getFileContentUrl = (id: number) => `/files/${id}/content`;

export const uploadFile = async ({
  file,
  type,
  onUploadProgress,
}: {
  file: File;
  type: string;
  onUploadProgress?: (progress: number) => void;
}): Promise<string> => {
  const crypto = window.crypto;
  const digest = await crypto.subtle.digest(
    "SHA-256",
    await file.arrayBuffer(),
  );
  const hashArray = Array.from(new Uint8Array(digest));
  const fileHash = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  const name = file.name;

  const presignedUrlResponse = await getPresignedUrl({
    file_hash: fileHash,
    name,
    type,
  });

  if ("id" in presignedUrlResponse) {
    return getFileContentUrl(presignedUrlResponse.id);
  }

  await uploadFileToUrl({
    url: presignedUrlResponse.presigned_url,
    file,
    onUploadProgress,
  });

  const storeFileResponse = await storeFile({
    name,
    file_hash: fileHash,
    type,
    path: presignedUrlResponse.path,
  });

  return getFileContentUrl(storeFileResponse.id);
};
