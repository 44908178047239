import type { IconProps } from "./IconProps";

export function ModuleDoc({ className, ...props }: IconProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={134}
      height={99}
      fill="none"
      {...props}
    >
      <path
        fill="#D6DEE9"
        fillOpacity={0.5}
        d="M127.182 60.82h-11.703c-2.868 0-5.27-1.952-5.27-4.424 0-1.236.62-2.341 1.55-3.122.93-.78 2.247-1.301 3.72-1.301h1.705c1.472 0 2.79-.52 3.72-1.301.93-.78 1.55-1.886 1.55-3.122 0-2.407-2.325-4.423-5.27-4.423H81.439c-.934 0-1.534-1.148-1.534-2.082 0-2.342-2.247-4.228-5.038-4.228H61.566a1.268 1.268 0 1 1 0-2.537h50.968c2.247 0 4.34-.78 5.813-2.016 1.472-1.236 2.402-2.993 2.402-4.879 0-3.838-3.72-6.895-8.215-6.895H86.209c-4.13 0-8.219-3.187-12.35-3.187H59.163a3.903 3.903 0 1 1 0-7.806h32.911c1.55 0 3.023-.52 4.03-1.366 1.008-.846 1.628-2.017 1.628-3.382C97.73 2.147 95.173 0 92.073 0H16.198c-1.55 0-3.023.52-4.03 1.366-1.007.846-1.628 2.017-1.628 3.383 0 2.601 2.558 4.748 5.658 4.748h1.938c2.557 0 4.727 1.756 4.727 3.968 0 1.106-.542 2.081-1.395 2.797-.852.715-2.015 1.17-3.332 1.17H8.758c-1.628 0-3.178.586-4.263 1.497-1.085.91-1.782 2.147-1.782 3.578 0 2.797 2.712 5.008 5.967 5.008h9.533c3.1 0 5.658 2.147 5.658 4.749 0 1.3-.62 2.471-1.628 3.317-1.007.846-2.402 1.366-3.952 1.366H9.765c-1.395 0-2.635.455-3.565 1.236-.93.78-1.472 1.821-1.472 2.992 0 2.342 2.247 4.228 5.037 4.228h6.898c3.953 0 7.13 2.667 7.13 5.985 0 1.626-.775 3.187-2.092 4.228-1.318 1.105-3.1 1.756-5.038 1.756h-9.61c-1.938 0-3.72.65-4.96 1.756C.775 60.17 0 61.665 0 63.291c0 3.253 3.178 5.92 7.053 5.92h29.675c3.277 0 6.496 1.821 9.774 1.821h5.405a3.74 3.74 0 1 1 0 7.48H38.75c-1.705 0-3.177.586-4.262 1.496-1.085.911-1.783 2.212-1.783 3.578 0 2.797 2.713 5.074 6.045 5.074h80.603c1.705 0 3.178-.586 4.263-1.496s1.782-2.212 1.782-3.578c0-2.797-2.712-5.074-6.045-5.074h-2.402c-2.481 0-4.418-1.69-4.418-3.707 0-1.04.465-1.952 1.317-2.602.775-.65 1.938-1.106 3.178-1.106h10.153c1.705 0 3.177-.585 4.263-1.496 1.085-.91 1.782-2.212 1.782-3.578 0-2.927-2.713-5.203-6.045-5.203Z"
      />
      <path
        fill="#10959E"
        d="M101.224 17.834v67.665c0 4.936-3.997 8.997-8.994 8.997h-2.935c-1.812 0-3.31-1-4.56-2.312-1.999-2.187-3.06-5.06-3.06-8.06v-7.81c0-3.124-2.562-5.685-5.685-5.685H39.826V17.834c0-2.812 2.311-5.123 5.122-5.123h51.155a5.105 5.105 0 0 1 5.121 5.123Z"
      />
      <path
        fill="#14BAC6"
        d="M86.764 99H29.87c-6.402 0-11.616-5.148-11.616-11.468v-7.494c0-3.257 2.706-5.93 6.006-5.93h48.511c3.3 0 6.007 2.672 6.007 5.93v8.145c0 3.063 1.122 6.125 3.234 8.406C83.266 97.957 84.85 99 86.764 99Z"
      />
      <path
        fill="#fff"
        d="M53.93 34.52a3.674 3.674 0 1 0 0-7.349 3.674 3.674 0 0 0 0 7.35ZM53.93 46.373a3.674 3.674 0 1 0 0-7.349 3.674 3.674 0 0 0 0 7.349ZM53.93 58.226a3.674 3.674 0 1 0 0-7.349 3.674 3.674 0 0 0 0 7.349ZM87.593 27.171h-21.81a3.674 3.674 0 0 0 0 7.35h21.81a3.674 3.674 0 0 0 0-7.35ZM87.475 39.024H65.902a3.793 3.793 0 1 0 0 7.586h21.573a3.793 3.793 0 0 0 0-7.586ZM87.593 50.877h-21.81a3.674 3.674 0 0 0 0 7.349h21.81a3.674 3.674 0 0 0 0-7.349Z"
      />
    </svg>
  );
}
