import type { ComponentPropsWithoutRef, ForwardedRef } from "react";

import { forwardRef, tw } from "@/utils";

const linkVariants = {
  gray: "text-gray-700 hover:text-gray-300 focus:text-gray-300",
} as const;

export type LinkVariant = keyof typeof linkVariants;

export interface LinkProps extends ComponentPropsWithoutRef<"a"> {
  variant?: LinkVariant;
}

export const Link = forwardRef(
  (
    { children, className, variant = "gray", ...props }: LinkProps,
    ref: ForwardedRef<HTMLAnchorElement>,
  ) => (
    <a
      ref={ref}
      className={tw(
        "cursor-pointer underline",
        linkVariants[variant],

        className,
      )}
      {...props}
    >
      {children}
    </a>
  ),
);
