import { t } from "ttag";

import { getRootSize } from "../../utils";
import { AvailableFields } from "../customFields";

const alignValues = {
  left: "justify-left",
  center: "justify-center",
  right: "justify-end",
} as const;

export type AlignValues = keyof typeof alignValues;

const SpacingComponent = ({ size }: { size: number }) => {
  const rootHeight = getRootSize().height;

  return (
    <div
      style={{
        height: `${size ? (size / rootHeight) * 500 : 10}px`,
      }}
    ></div>
  );
};

export const SpacingConfig = {
  itemRender: (
    <div className="flex h-20 flex-col items-center justify-center gap-2 py-4">
      <div className="flex h-10 w-24 flex-col items-center justify-center gap-2">
        <div className="h-4 w-full rounded-sm border border-gray-100"></div>
      </div>
      <p className="text-center text-gray-500">{t`Spacing`}</p>
    </div>
  ),
  label: "Spacing",
  fields: {
    size: {
      type: "custom",
      label: t`Space Height`,
      render: AvailableFields.slider,
    },
  },
  defaultProps: {
    size: 55,
  },
  render: SpacingComponent,
};
