import Lottie from "react-lottie-player";

import lottieJson from "@/components/animations/lotties/Spinner.json";
import { tw } from "@/utils";

interface SpinnerProps {
  className?: string;
}

export const Spinner = ({ className }: SpinnerProps) => (
  <div className="flex items-center justify-center">
    <Lottie
      animationData={lottieJson}
      loop={true}
      play
      className={tw("size-36", className)}
    />
  </div>
);
