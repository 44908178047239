import { useMutation } from "@tanstack/react-query";

import { uploadFile } from "@/api/files";
import { errorToast } from "@/ui";

export const useFiles = () => {
  const useUploadFile = useMutation({
    mutationFn: uploadFile,
    onError: (e) => {
      errorToast(e);
    },
  });

  return {
    useUploadFile,
  };
};
