import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";
import { z } from "zod";

import { Link } from "@/components";
import { Typography } from "@/components/Typography";
import { useAuth } from "@/hooks/useQuery";
import { ROUTES } from "@/router";
import { Button, Input, InputPassword } from "@/ui";
import { tw } from "@/utils";
import { AuthLogo } from "./AuthLogo";

const LoginSchema = z.object({
  email: z
    .string()
    .toLowerCase()
    .min(1, { message: t`Email is required` })
    .email({ message: t`Invalid email` }),
  password: z.string().min(1, { message: t`Password is required` }),
});

export type FormValues = z.infer<typeof LoginSchema>;

export const Login = () => {
  const { useLogin } = useAuth();

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    setError,
  } = useForm<FormValues>({
    resolver: zodResolver(LoginSchema),
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    useLogin.mutate(data, {
      onSuccess: () => {
        navigate(ROUTES.conceptsAndUseCases.base);
      },
      onError: () => {
        setError("password", {
          type: "manual",
          message: "Invalid Credentials",
        });
      },
    });
  };

  return (
    <div className="flex flex-1 grow flex-row items-center justify-center">
      <AuthLogo />
      <div className="flex h-screen w-1/2 grow flex-col items-center justify-center bg-white">
        <form
          className="w-auto md:w-96"
          onSubmit={(e) => handleSubmit(onSubmit)(e)}
        >
          <Typography className="text-4xl text-main-blue" font="medium">
            {t`Log in`}
          </Typography>
          <div className={tw("mt-6", !errors.email && "mb-3")}>
            <Input
              id={""}
              className="border-gray-300 bg-transparent"
              compact={!errors.email}
              placeholder="email@example.com"
              label={t`Email address`}
              {...register("email")}
              error={errors.email?.message}
            />
          </div>
          <InputPassword
            className="bg-transparent"
            compact={!errors.password}
            id={"password"}
            placeholder="********************"
            label={t`Password`}
            {...register("password")}
            error={errors.password?.message}
          />

          <Link
            onClick={() => navigate(ROUTES.forgotPassword.forgotPassword)}
            className="mt-3 flex justify-end"
          >
            {t`Forgot password?`}
          </Link>
          <div className="flex flex-col items-center justify-center">
            <Button
              disabled={!isDirty || useLogin.isPending}
              type="submit"
              className={"my-4 flex w-full text-lg font-semibold sm:my-8"}
              loading={useLogin.isPending}
            >
              {t`Log in`}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
