import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { generatePath } from "react-router-dom";
import { t } from "ttag";
import { z } from "zod";

import { ChevronArrowLeft, StarsModule, Switch } from "@/components";
import { Typography } from "@/components/Typography";
import { useModules } from "@/hooks/useQuery";
import { ROUTES } from "@/router";
import { Button, Input, Modal } from "@/ui";
import { TextArea } from "@/ui/form/TextArea";
import { tw } from "@/utils";

export const moduleSchema = z.object({
  name: z.string().min(1, { message: "Name is required" }).max(256, {
    message: "Name must be less than 256 characters",
  }),
  description: z
    .string()
    .min(1, { message: "Description is required" })
    .max(1000, { message: "Description is too long" }),
});

export type ModuleFormValues = z.infer<typeof moduleSchema>;

export const CreateModule = () => {
  const [modalCreateId, setModalCreateId] = useState<number>();
  const [memberOnlyChecked, setMemberOnlyChecked] = useState(false);
  const { useCreateModule } = useModules();
  const navigate = useNavigate();
  const {
    formState: { errors, isDirty },
    register,
    handleSubmit,
  } = useForm<ModuleFormValues>({
    resolver: zodResolver(moduleSchema),
  });

  const onSubmit: SubmitHandler<ModuleFormValues> = (data) => {
    useCreateModule.mutate(
      {
        name: data.name,
        description: data.description,
        required_membership: memberOnlyChecked ? "Premium" : "Free",
      },
      {
        onSuccess: (response) => {
          setModalCreateId(response.data.data.id);
        },
      },
    );
  };

  return (
    <div className="flex flex-col px-9 py-10">
      <div className="mb-6 flex flex-row items-center ">
        <Button
          variant="transparent"
          className="px-0"
          onClick={() => history.back()}
        >
          <ChevronArrowLeft />
        </Button>
        <Typography className="ml-4 text-3xl text-gray" font="semiBold">
          {t`Create Module`}
        </Typography>
      </div>
      <form
        className="flex w-full grow flex-col self-start rounded-xl border border-light-base p-6"
        onSubmit={(e) => handleSubmit(onSubmit)(e)}
      >
        <Input
          id="module-name"
          className="bg-transparent"
          compact={!errors.description}
          placeholder="Write the Module name"
          label={t`Module name`}
          {...register("name")}
          error={errors.name?.message}
          requiredMarker
        />

        <TextArea
          autoComplete="off"
          containerClassName="mt-4"
          compact={!errors.description}
          size="sm"
          id="description"
          placeholder={t`Write here the Module description`}
          className="resize-none bg-transparent"
          {...register("description")}
          error={errors?.description?.message}
          label={t`Description`}
          rows={12}
          requiredMarker
          maxLength={1000}
        />

        <div className="mt-4 flex flex-row items-center">
          <Switch
            checked={memberOnlyChecked}
            onCheckedChange={() => {
              setMemberOnlyChecked(!memberOnlyChecked);
            }}
          />
          <Typography className="ml-2 text-base text-gray">
            {t`Content for members only`}
          </Typography>
        </div>

        <div className="flex flex-row items-center self-end py-5">
          <Button
            className="px-7"
            variant="secondary"
            size="lg"
            onClick={() => navigate(ROUTES.conceptsAndUseCases.base)}
          >
            {t`Cancel`}
          </Button>
          <Button
            disabled={!isDirty || useCreateModule.isPending}
            type="submit"
            size="lg"
            className={tw("ml-2")}
            loading={useCreateModule.isPending}
          >
            {t`Save changes`}
          </Button>
        </div>
      </form>

      <Modal
        topChildren={
          <div className="mt-3 flex justify-center">
            <StarsModule />
          </div>
        }
        show={!!modalCreateId}
        className="py-4"
        title={t`Module successfully created`}
        onClose={() => {
          const path = generatePath(ROUTES.conceptsAndUseCases.moduleUnits, {
            id: modalCreateId!.toString(),
          });
          navigate(path);
        }}
        titleClasses="text-center"
        descriptionClasses="text-center text-gray font-base text-base"
      />
    </div>
  );
};
