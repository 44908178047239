import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import { msgid, ngettext, t } from "ttag";
import { z } from "zod";

import type { Unit } from "@/api/units";
import { Chip, Pencil, Typography } from "@/components";
import { useUnits } from "@/hooks/useQuery";
import { ROUTES } from "@/router";
import { Button, Input } from "@/ui";
import { tw } from "@/utils";

export const editUnitSchema = z.object({
  name: z.string().min(1, { message: "Name is required" }),
});

export type EditUnitFormValues = z.infer<typeof editUnitSchema>;

interface UnitCardProps {
  unitItem: Unit;
  moduleId: number;
  className?: string;
  units: number;
  onDeleteUnit: () => void;
}

export const UnitCard = ({
  unitItem,
  moduleId,
  className,
  onDeleteUnit,
}: UnitCardProps) => {
  const navigate = useNavigate();
  const [editUnit, setEditUnit] = useState(false);
  const { useUpdateUnit } = useUnits();
  const {
    formState: { errors, isDirty },
    register,
    handleSubmit,
    reset,
  } = useForm<EditUnitFormValues>({
    resolver: zodResolver(editUnitSchema),
    defaultValues: {
      name: unitItem.name,
    },
  });

  const onSubmit: SubmitHandler<EditUnitFormValues> = (data) => {
    useUpdateUnit.mutate({
      id: unitItem.id,
      name: data.name,
      module: moduleId,
    });
    setEditUnit(false);
    reset();
  };

  const onGoToSlides = () => {
    const path = generatePath(ROUTES.conceptsAndUseCases.editUnitSlides, {
      unitId: unitItem.id.toString(),
    });

    navigate(path);
  };

  return (
    <form
      onSubmit={(e) => handleSubmit(onSubmit)(e)}
      key={unitItem.id}
      className={tw(
        "flex flex-col rounded-xl border border-gray-500 px-4",
        className,
      )}
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <Typography className="text-lg text-gray-500" font="semiBold">
            {unitItem.name}
          </Typography>
          <Button
            variant="transparent"
            className="ml-1 mr-2 p-0"
            onClick={() => setEditUnit(!editUnit)}
          >
            <Pencil className="fill-gray-500" />
          </Button>
          {unitItem.slides && (
            <Chip className="bg-gray-100 text-main-blue" size="sm">
              {`${ngettext(
                msgid`${Number(unitItem.slides.length)} slide`,
                `${Number(unitItem.slides.length)} slides`,
                Number(unitItem.slides.length),
              )}`}
            </Chip>
          )}
        </div>
        <div className="flex flex-row">
          <Button
            variant="secondary"
            className="my-4 mr-2 py-2"
            onClick={() => {
              if (editUnit) {
                setEditUnit(false);
              } else {
                onDeleteUnit();
              }
            }}
          >
            {editUnit ? t`Cancel` : t`Delete unit`}
          </Button>
          {editUnit ? (
            <Button
              variant="tertiary"
              className="my-4 mr-2"
              disabled={useUpdateUnit.isPending || !isDirty}
              loading={useUpdateUnit.isPending}
              type="submit"
            >
              {t`Save changes`}
            </Button>
          ) : (
            <Button
              variant="tertiary"
              className="my-4 mr-2"
              type="button"
              onClick={onGoToSlides}
            >
              {t`Go to slides`}
            </Button>
          )}
        </div>
      </div>
      {editUnit && (
        <div className="mb-4 flex w-full grow flex-col self-start">
          <Input
            id="unit-name"
            className="bg-transparent"
            compact={!errors.name}
            placeholder="Unit name"
            requiredMarker
            label={t`Unit name`}
            {...register("name")}
            error={errors.name?.message}
          />
        </div>
      )}
    </form>
  );
};
