import type { IconProps } from "./IconProps";

export function CheckInCircle({ className }: IconProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={58}
      height={58}
      fill="none"
    >
      <circle cx={29} cy={29} r={28.5} fill="#D0F1F4" />
      <circle cx={29} cy={29} r={20.677} fill="#72D6DD" />
      <path
        stroke="#0C7077"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.5}
        d="m37.5 24.5-10.667 10-5.333-4.922"
      />
    </svg>
  );
}
