import { useMemo, useState } from "react";
import type { ColumnDef } from "@tanstack/react-table";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { t } from "ttag";

import { userToUserType } from "@/api/users";
import type { User } from "@/api/users";
import { Pencil, Trash, Typography } from "@/components";
import { TableComponent } from "@/components/Table";
import { useUsers } from "@/hooks/useQuery/useUsers";
import { EditUserModal } from "@/modals";
import { Button, Modal } from "@/ui";
import { formatToUs } from "@/utils/dateFormats";
import { EmptyInvites } from "../InvitesTable/EmptyInvites";

export const UsersTable = () => {
  const { useGetUsers, useDeleteUser } = useUsers();

  const getUsersQuery = useGetUsers();

  const [deleteInvite, setDeleteInvite] = useState({
    open: false,
    selectedInvite: 0,
  });

  const [editUser, setEditUser] = useState<{
    open: boolean;
    user: User | null;
  }>({
    open: false,
    user: null,
  });

  const columns = useMemo<ColumnDef<User>[]>(
    () => [
      {
        header: "User",
        accessorFn: (row: User) => row.name,
      },
      {
        header: "Email",
        accessorFn: (row: User) => row.email,
      },
      {
        header: "Type",
        accessorFn: (row: User) => userToUserType(row.role, row.membership),
      },
      {
        header: "Signed up Date",
        accessorFn: (row: User) => formatToUs(row.created_at),
      },
      {
        id: "actions",
        cell: ({ row }) => (
          <div className="flex flex-row items-center justify-end">
            <Button
              variant="transparent"
              onClick={() => {
                setEditUser({
                  open: true,
                  user: row.original,
                });
              }}
            >
              <Pencil className="fill-gray-500" />
            </Button>
            <Button
              variant="transparent"
              onClick={() => {
                setDeleteInvite({
                  open: true,
                  selectedInvite: row.original.id,
                });
              }}
            >
              <Trash />
            </Button>
          </div>
        ),
      },
    ],
    [],
  );

  const table = useReactTable({
    data: getUsersQuery.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const closeModal = () => setDeleteInvite({ open: false, selectedInvite: 0 });

  if (getUsersQuery.data?.length === 0) {
    return <EmptyInvites className="mt-32" />;
  }

  return (
    <div className="flex w-full grow flex-col self-start py-6">
      <TableComponent
        tableData={table}
        onClickRow={(row) =>
          setEditUser({
            open: true,
            user: row,
          })
        }
      />
      {editUser.user?.email && (
        <EditUserModal
          editUser={{ ...editUser, user: editUser.user || null }}
          onClose={() =>
            setEditUser({
              open: false,
              user: null,
            })
          }
        />
      )}
      <Modal
        show={deleteInvite.open}
        title={t`Remove user`}
        onClose={closeModal}
      >
        <div className="flex flex-col">
          <Typography className="mb-6 text-base font-medium text-gray">
            {t`Are you sure you want to remove the user?`}
          </Typography>
          <div className="flex flex-row">
            <Button
              variant="secondary"
              className="mr-1 w-1/2"
              disabled={useDeleteUser.isPending}
              onClick={closeModal}
            >
              {t`Cancel`}
            </Button>
            <Button
              disabled={useDeleteUser.isPending}
              variant="primary"
              className="ml-1 w-1/2"
              onClick={() => {
                useDeleteUser.mutate(deleteInvite.selectedInvite, {
                  onSuccess: closeModal,
                });
              }}
            >
              {t`Remove user`}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
