import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { t } from "ttag";
import { z } from "zod";

import { useUnits } from "@/hooks/useQuery";
import { Button, Input } from "@/ui";
import { tw } from "@/utils";

interface CreateUnitProps {
  moduleId: number;
  className?: string;
  onClose: () => void;
}

export const unitSchema = z.object({
  name: z.string().min(1, { message: "Name is required" }),
});

export type UnitFormValues = z.infer<typeof unitSchema>;

export const CreateUnit = ({
  moduleId,
  className,
  onClose,
}: CreateUnitProps) => {
  const { useCreateUnit } = useUnits();
  const {
    formState: { errors, isDirty },
    register,
    handleSubmit,
    reset,
  } = useForm<UnitFormValues>({
    resolver: zodResolver(unitSchema),
  });

  const onSubmit: SubmitHandler<UnitFormValues> = (data) => {
    useCreateUnit.mutate({
      name: data.name,
      module: moduleId,
    });
    reset();
  };
  return (
    <div className={tw("flex flex-col", className)}>
      <form
        className="flex w-full grow flex-col self-start rounded-xl border border-light-base p-6"
        onSubmit={(e) => handleSubmit(onSubmit)(e)}
      >
        <Input
          id="unit-name"
          placeholder={t`Write here the Unit name`}
          label={t`Unit name`}
          {...register("name")}
          error={errors.name?.message}
          requiredMarker
        />

        <div className="flex flex-row items-center self-end pt-5">
          <Button
            className="px-7"
            variant="secondary"
            size="lg"
            onClick={onClose}
          >
            {t`Cancel`}
          </Button>
          <Button
            disabled={!isDirty || useCreateUnit.isPending}
            type="submit"
            size="lg"
            className="ml-2"
            loading={useCreateUnit.isPending}
          >
            {t`Save changes`}
          </Button>
        </div>
      </form>
    </div>
  );
};
