import type { ServiceResponse } from "./api.types";
import { getApi } from "./axios";

export type Membership = "Free" | "Premium";

export interface Invitation {
  id: number;
  name: string;
  email: string;
  membership: Membership;
  role: string;
}

interface CreateInvitationParams {
  name: string;
  email: string;
  membership: Membership;
  role: string;
}

interface UpdateInviteParams {
  id: number;
  email: string;
}

interface AcceptInvitationParams {
  token: string;
  password: string;
  password_confirmation: string;
}
interface AcceptInvitationResponse {
  id: number;
  name: string;
  email: string;
}

export const getInvitations = async () => {
  const { data } =
    await getApi().get<ServiceResponse<Invitation[]>>("/invitations");
  return data.data;
};

export const getInvitation = async (id: number) => {
  const { data } = await getApi().get<ServiceResponse<Invitation>>(
    `/invitations/${id}`,
  );
  return data.data;
};

export const createInvitation = async (params: CreateInvitationParams) => {
  const data = await getApi().post("/invitations", params);
  return data;
};

export const deleteInvitation = async (id: number) => {
  const data = await getApi().delete(`/invitations/${id}`);
  return data;
};

export const acceptInvitation = async (params: AcceptInvitationParams) => {
  const { data } = await getApi({
    isPrivateApi: false,
  }).post<AcceptInvitationResponse>(
    `/invitations/${params.token}/accept`,
    params,
    {
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
    },
  );
  return data;
};

export const updateInvitation = async (params: UpdateInviteParams) => {
  const data = await getApi().put(`/units/${params.id}`, {
    email: params.email,
  });
  return data;
};
