import type { ComponentConfig } from "@measured/puck";
import { DropZone } from "@measured/puck";
import { t } from "ttag";

import { tw } from "@/utils";
import { Section } from "../../components/Section";

const filledValues = {
  transparent: "bg-transparent",
  white: "border border-gray-200/70 bg-white",
} as const;

export interface ColumnsProps {
  filled: keyof typeof filledValues;
  distribution: "auto" | "manual";
  columns: {
    span?: number;
  }[];
}

export const ColumnsConfig: ComponentConfig<ColumnsProps> & {
  itemRender: JSX.Element;
} = {
  itemRender: (
    <div className="flex h-24 flex-col items-center justify-center gap-2 py-4">
      <div className="flex h-10 w-24 flex-row justify-center gap-2">
        <div className="w-10 rounded-sm border border-gray-100 bg-gray-50"></div>
        <div className="w-10 rounded-sm border border-gray-100 bg-gray-50"></div>
      </div>
      <p className="text-center text-gray-500">{t`Column`}</p>
    </div>
  ),
  fields: {
    filled: {
      type: "radio",
      label: t`Filled`,
      options: [
        {
          value: "transparent",
          label: t`Transparent`,
        },
        {
          value: "white",
          label: t`White`,
        },
      ],
    },
    distribution: {
      type: "radio",
      label: t`Distribution`,
      options: [
        {
          value: "auto",
          label: t`Auto`,
        },
        {
          value: "manual",
          label: t`Manual`,
        },
      ],
    },
    columns: {
      type: "array",
      label: t`Columns`,
      getItemSummary: (col, id) =>
        `Column ${(id ?? 0) + 1}, span ${
          col.span ? Math.max(Math.min(col.span, 12), 1) : "auto"
        }`,
      arrayFields: {
        span: {
          label: "Span (1-12)",
          type: "number",
          min: 0,
          max: 12,
        },
      },
    },
  },
  defaultProps: {
    filled: "transparent",
    distribution: "auto",
    columns: [{}, {}],
  },
  render: ({ columns, distribution, filled }) => {
    return (
      <Section>
        <div
          className="grid min-h-0 min-w-0 grid-cols-12 flex-col gap-6"
          style={{
            gridTemplateColumns:
              distribution === "manual"
                ? "repeat(12, 1fr)"
                : `repeat(${columns.length}, 1fr)`,
          }}
        >
          {columns.map(({ span }, idx) => (
            <div
              key={idx}
              style={{
                display: "flex",
                flexDirection: "column",
                gridColumn:
                  span && distribution === "manual"
                    ? `span ${Math.max(Math.min(span, 12), 1)}`
                    : "",
                minHeight: 125,
              }}
              className={tw("rounded-lg", filledValues[filled])}
            >
              <DropZone
                zone={`column-${idx}`}
                disallow={["Hero", "Logos", "Stats"]}
              />
            </div>
          ))}
        </div>
      </Section>
    );
  },
};
