import type { IconProps } from "./IconProps";

export function ChevronArrowLeft({ className, ...props }: IconProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={9}
      height={17}
      fill="none"
      {...props}
    >
      <path
        stroke="#354863"
        strokeLinecap="round"
        strokeWidth={2}
        d="M7.916 1.25 1.521 7.646a1.208 1.208 0 0 0 0 1.708l6.395 6.396"
      />
    </svg>
  );
}
